import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ThemeDefaultValues } from '../ThemeProvider';
import { TextField as TextFieldNew } from '@material-ui/core';

import { TextField, FlatButton, SelectField, AutoComplete, DropDownMenu } from 'material-ui';
import InputBase from '@material-ui/core/InputBase';
import { getFormattedDate3, getfloatingValue } from '../utils/helperFunctions';

const itemStyles = {
  itemInput: {
    height: '30px',
    border: 'none',
    outline: 'none',
    marginTop: '5px',
    fontSize: '14px',
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontFamily: ThemeDefaultValues.baseFontFamily,
    textOverflow: 'ellipsis',
    width: 'calc(100% - 5px)',
    color: 'rgba(0, 0, 0, 0.87)',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  itemInputLineStyle: {
    bottom: '5px',
    width: '100%',
    margin: '0px',
    position: 'relative',
    boxSizing: 'content-box',
    borderTop: 'none #f5f5f5',
    borderLeft: 'none #f5f5f5',
    borderRight: 'none #f5f5f5',
    borderBottom: '1px solid #f5f5f5',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  itemInputContainerStyle: {
    width: '100%',
    height: '30px',
    cursor: 'auto',
    position: 'relative',
    display: 'inline-block',
    backgroundColor: 'transparent',
    transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  inputHintStyle: {
    bottom: '-2px',
    fontSize: '14px',
    width: 'inherit',
    textAlign: 'center',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.3)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  dropdownIconStyle: {
    top: '3px',
    right: '-5px',
    border: '0px',
    height: '30px',
    padding: '0px',
    outline: 'none',
    fontSize: '0px',
    cursor: 'pointer',
    background: 'none',
    userSelect: 'none',
    textAlign: 'right',
    overflow: 'visible',
    position: 'absolute',
    fontWeight: 'inherit',
    textDecoration: 'none',
    boxSizing: 'border-box',
    display: 'inline-block',
    fill: 'rgb(224, 224, 224)',
    color: 'rgb(224, 224, 224)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  dropdownStyle: {
    height: '30px',
    display: 'inline-block',
    fontSize: '14px',

    outline: 'none',
    position: 'relative',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  dropdownUnderlineStyle: {
    bottom: '0px',
    width: 'inherit',
    margin: '0px',
    position: 'relative',
    boxSizing: 'content-box',
    borderTop: 'none #f5f5f5',
    borderLeft: 'none #f5f5f5',
    borderRight: 'none #f5f5f5',
    borderBottom: '1px solid #f5f5f5',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  dropdownMenuStyle: {
    width: 'inherit',
    padding: '0px',
    position: 'relative',
    boxSizing: 'border-box'
  },
  dropdownMenuItemStyle: {
    width: 'inherit',
    fontSize: '14px',
    boxSizing: 'border-box',

    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgb(255, 255, 255)',
    transition:
      'transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  dropdownLabelStyle: {
    top: '5px',
    height: '30px',
    width: 'inherit',
    overflow: 'hidden',
    lineHeight: '30px',
    paddingLeft: '0px',
    textAlign: 'center',
    paddingRight: '0px',
    position: 'relative',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  flatButtonStyle: {
    border: '10px',
    height: 'unset',
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
    minWidth: 'unset',
    transition: 'none',
    lineHeight: 'unset',
    textAlign: 'center',
    textDecoration: 'none',

    backgroundColor: 'transparent',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
  },
  flatButtonLabelStyle: {
    fontSize: 'inherit',
    paddingLeft: 'unset',
    paddingRight: 'unset',
    textTransform: 'none',
    fontWeight: 'inherit'
  }
};

const itemDescriptionStyles = {
  itemInput: {
    height: '30px',
    color: 'blue',
    border: 'none',
    outline: 'none',
    marginTop: '5px',
    fontSize: '14px',
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',

    textOverflow: 'ellipsis',
    width: 'calc(100% - 5px)',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  itemInputLineStyle: {
    bottom: 'none',
    width: '100%',
    margin: '0px',
    position: 'relative',
    boxSizing: 'content-box',
    borderTop: 'none #f5f5f5',
    borderLeft: 'none #f5f5f5',
    borderRight: 'none #f5f5f5',
    borderBottom: 'none #f5f5f5',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  },
  itemInputContainerStyle: {
    width: '100%',
    height: '30px',
    cursor: 'auto',
    position: 'relative',
    display: 'inline-block',
    backgroundColor: 'transparent',
    transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    ':focus': {
      borderBottom: 'none #f5f5f5',
      boxShadow: 'none'
    }
  }
};

const Img = styled.img`
  padding-top: 5px;
  padding-right: 10px;
  width: ${props => (props.iconWidth ? props.iconWidth : '20px')};
  height: ${props => (props.iconHeight ? props.iconHeight : '20px')};
`;

const StyledSpan = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ItemSpan = styled.div`
  height: ${props => (props.height ? props.height : '58px')};
  width: ${props => (props.width ? props.width : 'initial')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease 0s;
`;

const handleOnchangeInputText = (onChange, event, type) => {
  if (type === 'number') {
    (!isNaN(event.target.value) || event.target.value === '-') &&
      onChange(event.target.value || '');
  } else {
    onChange(event.target.value || '');
  }
};

const ItemAutoComplete = ({
  hint,
  width,
  height,
  onFocus,
  inputRef,
  searchText,
  dataSource,
  onUpdateInput,
  onNewRequest,
  id,
  labelText
}) => (
  <ItemSpan height={height} width={width}>
    <AutoComplete
      openOnFocus={true}
      autoComplete="no"
      ref={inputRef}
      hintText={hint}
      floatingLabelText={labelText}
      onFocus={onFocus}
      filter={AutoComplete.fuzzyFilter}
      dataSource={dataSource}
      onNewRequest={onNewRequest}
      searchText={searchText}
      id={id || 'auto-complete'}
      onUpdateInput={onUpdateInput}
      maxSearchResults={50}
      style={itemStyles.itemInputContainerStyle}
      listStyle={{
        fontFamily: 'Roboto',
        fontSize: '12px',
        width: 'fit-content',
        maxHeight: '300px',
        overflow: 'auto'
      }}
      underlineStyle={{ ...itemStyles.dropdownUnderlineStyle, bottom: '4px' }}
      hintStyle={{
        ...itemStyles.inputHintStyle,
        bottom: '2px',
        textAlign: 'normal'
      }}
      textFieldStyle={{
        width: 'inherit',
        fontFamily: 'Roboto',
        fontSize: '14px',
        height: '30px',
        textAlign: 'none',
        marginTop: '4px'
      }}
    />
  </ItemSpan>
);

const ItemDropdown = ({ children, value, width, onChange, onFocus, id }) => (
  <DropDownMenu
    id={id}
    value={value}
    onFocus={onFocus}
    autoWidth={false}
    iconStyle={itemStyles.dropdownIconStyle}
    menuStyle={itemStyles.dropdownMenuStyle}
    labelStyle={itemStyles.dropdownLabelStyle}
    menuItemStyle={itemStyles.dropdownMenuItemStyle}
    underlineStyle={itemStyles.dropdownUnderlineStyle}
    onChange={(event, index, v) => onChange(v)}
    style={{ ...itemStyles.dropdownStyle, width }}
  >
    {children}
  </DropDownMenu>
);

const ItemTextInput = ({
  isInputDescription,
  width,
  field,
  onChange,
  value,
  type = 'text',
  onBlur,
  onFocus,
  id = `textField`,
  page,
  onKeyUp,
  containerHeight,
  containerWidth,
  hint,
  readOnly,
  errorText,
  disabled,
  conatinerStyle,
  autoFocus = false,
  tabIndex,
  inputStyle,
  onKeyDown
}) => {
  const style = isInputDescription ? itemDescriptionStyles : itemStyles;
  return (
    <ItemSpan height={containerHeight || 'unset'} width={containerWidth}>
      <TextField
        id={id || ''}
        tabIndex={tabIndex}
        disabled={disabled}
        errorText={errorText}
        value={value}
        onBlur={onBlur}
        placeholder={hint}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        onFocus={e => {
          onChange && e.target.select();
          onFocus && onFocus(e);
        }}
        type={type}
        textareaStyle={{ width: width }}
        underlineStyle={style.itemInputLineStyle}
        readOnly={readOnly === true ? 'readonly' : false}
        onChange={event => {
          handleOnchangeInputText(onChange, event, type);
        }}
        inputStyle={{
          ...style.itemInput,
          textAlign: field === 'name' ? 'none' : 'center', // This is done for create voucher drawer tax alingment
          ...inputStyle
        }}
        hintStyle={{
          ...style.inputHintStyle,
          textAlign: field === 'name' ? 'none' : 'center'
        }}
        style={{
          ...style.itemInputContainerStyle,
          ...conatinerStyle,
          marginTop: page === 'showNote' ? '-10px' : 'initial',
          fontSize: '14px',
          width
        }}
        onKeyDown={onKeyDown}
      />
    </ItemSpan>
  );
};

const TextInputField = ({
  id,
  type,
  hint,
  width,
  value,
  imgSrc,
  onBlur,
  onKeyUp,
  onFocus,
  onChange,
  readOnly,
  autoFocus,
  errorText,
  inputRef,
  iconHeight,
  iconWidth,
  underline,
  labelText,
  labelFixed,
  textColor,
  labelSize,
  disabled = false,
  style,
  hintStyle,
  errorStyle,
  floatingLabelStyle,
  containerStyle = {},
  spanWidth = 'auto',
  marginTop,
  placeholder
}) => (
  <StyledSpan style={{ width: spanWidth, ...containerStyle }}>
    {imgSrc && <Img src={imgSrc} iconHeight={iconHeight} iconWidth={iconWidth} />}
    <TextField
      id={id || labelText}
      value={value}
      hintText={hint}
      ref={inputRef}
      onBlur={onBlur}
      autoFocus={autoFocus === 'true' ? true : false}
      onKeyUp={onKeyUp}
      onFocus={e => {
        onChange && e.target.select();
        onFocus && onFocus(e);
      }}
      floatingLabelFixed={labelFixed}
      underlineShow={underline}
      type={type || 'text'}
      errorText={errorText || ''}
      floatingLabelText={labelText}
      textareaStyle={{ width: width }}
      disabled={disabled}
      floatingLabelStyle={{
        size: '10px',
        color: '#4f4f4f',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'inherit',
        ...floatingLabelStyle
      }}
      hintStyle={{
        size: '13px',
        color: '#828282',
        ...hintStyle
      }}
      readOnly={readOnly === true ? 'readonly' : false}
      onChange={event => {
        handleOnchangeInputText(onChange, event, type);
      }}
      style={{
        width: width,
        color: 'rgba(0,0,0,0.3)',
        marginTop: marginTop || '-14px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        ...style
      }}
      errorStyle={errorStyle}
      autoComplete="no"
      placeholder={placeholder}
    />
  </StyledSpan>
);
let oldValue = 0;
const TextNumberField = ({
  id,
  autoFocus,
  value,
  onChange,
  decimalRound,
  hint,
  onFocus,
  containerWidth,
  containerHeight,
  inputStyle,
  disabled,
  onBlur,
  onKeyDown
}) => {
  const isQty = new RegExp('qty').test(id ? id.toLowerCase() : '');
  const currencyDecimalPoints = useSelector(state => state.currentCompany.currencyDecimals);
  const itemQtyDecimalPoints = useSelector(state => state.currentCompany.itemQtyDecimals);
  const decimalPoints = decimalRound || isQty ? itemQtyDecimalPoints : currencyDecimalPoints;
  return (
    <ItemTextInput
      id={id}
      inputStyle={{ fontSize: '0.8125rem', ...inputStyle }}
      autoFocus={autoFocus} //reverted as causing bug IN-866
      hint={hint}
      type="number"
      // onKeyUp={e => onKeyPress(e, 'Qty', row.index)}
      value={value}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      onFocus={() => {
        oldValue = value;
        // props.lineItemIndex(row.index);
        // value === 0 && onChange('');
        onFocus && onFocus();
      }}
      onBlur={() => {
        // if (true || !regex.test(value))
        if (oldValue !== value) {
          onChange(getfloatingValue(value, decimalPoints));
        }
        onBlur && onBlur();
      }}
      onChange={value => {
        onChange(value);
      }}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  );
};

const TextNumberFieldNew = ({
  label,
  value = '',
  onChange,
  helperText,
  errorState,
  id,
  width,
  onBlur,
  required,
  disabled,
  classes,
  autoFocus,
  placeholder,
  onFocus,
  style,
  inputStyle,
  decimalRound = 2,
  variant,
  endAdornment,
  startAdornment,
  onKeyDown,
  InputLabelStyle
}) => {
  let oldValue;

  const isQty = new RegExp('qty').test(id ? id.toLowerCase() : '');
  const currencyDecimalPoints = useSelector(state => state.currentCompany.currencyDecimals);
  const itemQtyDecimalPoints = useSelector(state => state.currentCompany.itemQtyDecimals);
  const decimalPoints = decimalRound || isQty ? itemQtyDecimalPoints : currencyDecimalPoints;
  return (
    <TextInputFieldNew
      autoFocus={autoFocus}
      classes={classes}
      label={label}
      variant={variant}
      placeholder={placeholder}
      width={width}
      style={style}
      inputStyle={inputStyle}
      onChange={val => onChange(val)}
      onKeyDown={onKeyDown}
      onBlur={() => {
        // if (true || !regex.test(value))
        if (oldValue !== value) {
          onChange(getfloatingValue(value, decimalPoints));
        }
        onBlur && onBlur(value);
      }}
      onFocus={e => {
        oldValue = value;
        onFocus && onFocus(e);
        e.target.select();
      }}
      value={value}
      id={id}
      helperText={helperText}
      errorState={errorState}
      required={required}
      disabled={disabled}
      endAdornment={endAdornment}
      startAdornment={startAdornment}
      type="number"
      InputLabelStyle={InputLabelStyle}
    />
  );
};

const TextInputFieldNew = ({
  label,
  type,
  value = '',
  onChange,
  helperText,
  errorState,
  id,
  width,
  onBlur,
  required,
  disabled,
  endAdornment,
  startAdornment,
  classes,
  autoFocus,
  placeholder,
  onFocus,
  style,
  inputStyle,
  multiline = false,
  rowsMax = 3,
  variant,
  onKeyUp,
  onKeyDown,
  size,
  InputLabelStyle
}) => (
  <TextFieldNew
    autoFocus={autoFocus}
    multiline={multiline}
    variant={variant}
    rowsMax={rowsMax}
    classes={classes}
    type={type}
    label={label}
    placeholder={placeholder}
    style={{ width: width || '100%', marginTop: '0.3125rem', ...style }}
    InputProps={{
      style: { fontSize: '0.875rem', ...inputStyle },
      endAdornment: endAdornment,
      startAdornment: startAdornment
    }}
    InputLabelProps={{ style: { fontSize: '0.875rem', width: '100%', ...InputLabelStyle } }}
    onChange={event => handleOnchangeInputText(onChange, event, type)}
    onBlur={onBlur}
    onFocus={e => (onFocus ? onFocus(e) : onChange && e.target.select())}
    value={value}
    id={id}
    helperText={helperText}
    error={errorState}
    required={required}
    disabled={disabled}
    onKeyUp={onKeyUp}
    onKeyDown={onKeyDown}
    size={size}
  />
);

const TextAreaWrapper = ({ id = 'textArea', label, value, updateHandler, ...props }) => {
  const [textAreaValue, setValue] = useState('');
  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <TextInputFieldNew
      multiline
      onChange={value => {
        setValue(value);
      }}
      onBlur={() => {
        updateHandler(textAreaValue);
      }}
      id={id}
      label={label}
      value={textAreaValue}
      {...props}
    />
  );
};
// No Border
const LabelText = ({
  id,
  type,
  inputWidth = '55%',
  value,
  imgSrc,
  onBlur,
  onKeyUp,
  onFocus,
  onChange,
  readOnly,
  autoFocus,
  errorText,
  inputRef,
  iconHeight,
  iconWidth,
  labelText,
  textColor,
  disabled,
  style
}) => (
  <StyledSpan>
    {imgSrc && <Img src={imgSrc} iconHeight={iconHeight} iconWidth={iconWidth} />}
    <div
      style={{
        color: '#868686',
        fontFamily: 'Roboto,Regular',
        width: '100%'
      }}
    >
      <span
        style={{
          marginRight: 30,
          fontSize: 12,
          color: textColor
        }}
      >
        {' '}
        {labelText}
      </span>
      <InputBase
        id={id || ''}
        value={value}
        // hintText={hint}
        ref={inputRef}
        onBlur={onBlur}
        autoFocus={autoFocus === 'true' ? true : false}
        onKeyUp={onKeyUp}
        onFocus={e => {
          onChange && e.target.select();
          onFocus && onFocus(e);
        }}
        // floatingLabelFixed={labelFixed}
        // underlineShow={underline}
        type={type || 'text'}
        error={errorText !== ''}
        // floatingLabelText={labelText}
        // textareaStyle={{ width: width }}
        disabled={disabled}
        // floatingLabelStyle={{ size: labelSize, color: textColor }}
        readOnly={readOnly === true ? 'readonly' : false}
        onChange={event => onChange(event.target.value || '')}
        style={{
          width: inputWidth,
          color: 'rgba(0,0,0)',
          fontFamily: 'Roboto',
          fontSize: 14,
          ...style
        }}
      />
    </div>
  </StyledSpan>
);

const Dropdown = ({
  imgSrc,
  labelSize,
  width,
  labelText,
  hintText,
  iconWidth,
  iconHeight,
  onChange,
  value,
  disable,
  inputRef,
  children,
  errorText,
  style,
  spanWidth = '100%',
  lineWidth = '100%',
  floatingLabelStyle,
  labelStyle,
  errorStyle
}) => (
  <StyledSpan style={{ width: spanWidth }}>
    {imgSrc && <Img src={imgSrc} iconHeight={iconHeight} iconWidth={iconWidth} />}
    <SelectField
      value={value}
      autoWidth={true}
      ref={inputRef}
      disabled={disable}
      hintText={hintText}
      errorText={errorText || ''}
      errorStyle={errorStyle}
      floatingLabelText={labelText}
      floatingLabelStyle={floatingLabelStyle}
      iconStyle={{ padding: '16px 0 16px 20px' }}
      menuStyle={{ paddingRight: '0px', width: lineWidth }}
      listStyle={{ width: lineWidth }}
      underlineStyle={{ width: lineWidth }}
      selectedMenuItemStyle={{ paddingRight: '0px', width: lineWidth }}
      onChange={(event, index, value) => onChange(value)}
      style={{ width: width, marginTop: '-14px', fontFamily: 'Roboto', ...style }}
      labelStyle={{ paddingRight: '26px', fontSize: labelSize, ...labelStyle }}
    >
      {children}
    </SelectField>
  </StyledSpan>
);
const DateTime = ({
  value,
  width,
  onChange,
  labelText,
  inputProps,
  id,
  helperText,
  errorState,
  onBlur,
  classes
}) => (
  // <DatePicker
  //   autoOk={true}
  //   value={value || date}
  //   hintText={hint}
  //   ref={inputRef}
  //   onKeyUp={onKeyUp}
  //   container="inline"
  //   id={id || 'uniqueId'}
  //   underlineShow={underline}
  //   floatingLabelText={labelText}
  //   maxDate={maxDate || new Date()}
  //   onChange={(event, date) => onChange(date)}
  //   textFieldStyle={{
  //     width: 'inherit',
  //     fontSize: '14px',
  //     color: '#428bca',
  //     cursor: 'pointer'
  //   }}
  //   style={{
  //     width: width,
  //     color: '#428bca',
  //     marginTop: marginOnTop || '-14px',
  //     fontSize: '14px'
  //   }}
  // />
  <TextFieldNew
    id={id || 'date'}
    label={labelText}
    type="date"
    // defaultValue={getFormattedDate3(new Date())}
    value={value}
    onBlur={onBlur}
    style={{ width: width || 'auto' }}
    onChange={e => onChange(e.target.value)}
    className="text-input"
    inputProps={inputProps}
    helperText={helperText}
    error={errorState}
    // min={min}
    // max={max}
    InputLabelProps={{
      shrink: true
    }}
    classes={classes}
  />
);

const AutoFillInput = ({
  hint,
  width = '100%',
  fullWidth = true,
  labelText,
  searchText,
  dataSource,
  onUpdateInput,
  errorText,
  filter,
  textFieldStyle,
  dataSourceConfig,
  onNewRequest,
  onClose,
  maxSearchResults,
  onBlur,
  marginTop,
  style,
  autoFocus
}) => (
  <AutoComplete
    autoComplete="no"
    openOnFocus={true}
    hintText={hint}
    fullWidth={fullWidth}
    dataSource={dataSource}
    searchText={searchText}
    floatingLabelText={labelText}
    onNewRequest={onNewRequest}
    onUpdateInput={onUpdateInput}
    onClose={onClose}
    filter={filter ? filter : AutoComplete.fuzzyFilter}
    maxSearchResults={maxSearchResults ? maxSearchResults : 100}
    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
    listStyle={{
      width: 'inherit',
      fontFamily: 'Roboto',
      maxHeight: '200px',
      overflow: 'auto'
    }}
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    style={{
      ...style,
      width: width,
      marginTop: marginTop ? marginTop : '-14px'
    }}
    textFieldStyle={{
      //TODO merge conflict
      fontFamily: 'Roboto',
      fontSize: '14px',
      ...textFieldStyle
    }}
    errorText={errorText}
    dataSourceConfig={dataSourceConfig}
    onBlur={onBlur}
    autoFocus={autoFocus}
  />
);

const TextButton = ({ label, onClick, color }) => (
  <FlatButton
    label={label}
    onClick={onClick}
    style={itemStyles.flatButtonStyle}
    labelStyle={{ ...itemStyles.flatButtonLabelStyle, color: color }}
  />
);

const DateInput = props => (
  <TextField
    id="date"
    label={props.labelText}
    type="date"
    // defaultValue={getFormattedDate3(new Date())}
    value={props.value || getFormattedDate3(new Date())}
    style={{ width: props.width ? props.width : 'auto', fontSize: '14px', paddingTop: '7px' }}
    onChange={e => props.onChange(e, e.target.value)}
    className="text-input"
  />
);

export {
  Dropdown,
  DateTime,
  TextButton,
  ItemDropdown,
  ItemTextInput,
  AutoFillInput,
  TextInputField,
  LabelText,
  ItemAutoComplete,
  DateInput,
  TextInputFieldNew,
  TextNumberField,
  TextAreaWrapper,
  TextNumberFieldNew
};
