export const API_URL = process.env.REACT_APP_API_URL;

export const EDIT = `edit`;
export const CREATE = `create`;
export const UPDATE = `Update`;
export const SAVE = `Save`;
export const SAVE_AND_PRINT = `Save and Print`;
export const UPDATE_AND_PRINT = `Update and Print`;
export const UPDATING = `Updating`;
export const SAVING = `Saving`;
export const NEXT = `Next`;
export const NEW = 'new';
export const CONTACTS = `Contacts`;
export const SECONDARY_CONTACTS = `Secondary Contacts`;
export const BASIC_DETAILS = `Basic Details`;
export const SHIPPING = `Shipping Address`;
export const BILLING = `GST & Billing`;
export const BANK_DETAILS = `Bank Details`;
export const SALES_DISCOUNT = `Sales Discount`;
export const PURCHASE_DISCOUNT = 'Purchase Discount';
export const TRANSPORTATION = `Transportation`;
export const TRANSPORTER = `transporter`;
export const SALES_PERSON = 'sales-person';
export const SALES_PERSON_LIST = 'sales-person-list';
export const TRANSPORTER_LIST = `transporter_list`;
export const AJ_TALLY_PARTY_LIST = `AJ_TALLY_PARTY_list`;
export const PARTY_LIST = 'partyPickerList';
export const CUSTOMER = `customer`;
export const VENDOR = `vendor`;
export const PARTY_STATEMENT = `partyStatement`;
export const UNRECONCILED_VOUCHERS = 'unreconciledVouchers';
export const ATTRIBUTES = 'Attributes';
export const ROUND_OFF = 'Round Off';
export const RATE_CODE = 'Rate Code';
export const OLD_TAX_REGIME = 'Old Tax Regime';
export const UNRECONCILED_VOUCHER_STATEMENT = 'unreconciledVoucherStatement';
export const ALL_UNRECONCILED = 'all-unreconciled';
export const CONST_RECON_MODAL = 'openReconcileModal';
export const OPENING_BALANCE = 'opening-balance';
export const RECONCILED_VOUCHERS_VIEW_MODAL = 'openReconciledVoucherViewModal';
export const RECONCILED_SELCTED_GROUP_MODAL = 'openReconciledSelectedGroupModal';
export const ITEM_TAGS_KEY = 'tags';
export const ITEMS_MASTER_KEY = 'items-master';
export const DIALOG_TAGS_KEY = 'dialogTags';
export const USER_TOKEN = 'userAccessToken';
export const CURRENT_COMPANY_TOKEN = 'currentCompanyToken';
export const CURRENT_COMPANY_PDF_TOKEN = 'currentCompanyPdfToken';
export const ADD_TRANSPORTER = 'Add Transporter';
export const ADD_SALES_PERSON = 'Add Sales Person';
export const ADD_PARTY = 'Add Party';
export const INDIA = 'India';

// keys for 3rd party tracking applications
export const CLEVERTAP_ID = '6ZR-KZ5-9W5Z';
export const MIXPANEL_ID = '1a4322043a0c704b67cc94f17f57244f';

// redux action types
export const REGISTER_COMPANY_PAYLOAD = 'REGISTER_COMPANY_PAYLOAD';
export const SIMILAR_COMPANIES_CHECK = 'SIMILAR_COMPANIES_CHECK';
export const SIMILAR_COMPANIES_DATA = 'SIMILAR_COMPANIES_DATA';
export const CREACTE_SALES_PURCHASE = 'CREACTE_SALES_PURCHASE';
export const VOUCHER_DURATION_TYPE = 'VOUCHER_DURATION_TYPE';
export const HANDLE_TRANSPORT_DONE = 'HANDLE_TRANSPORT_DONE';
export const UPDATE_VOUCHER_TYPES = 'UPDATE_VOUCHER_TYPES';
export const LAST_VISITED_VOUCHER = 'LAST_VISITED_VOUCHER';
export const BUSINESS_ACCOUNTS = 'BUSINESS_ACCOUNTS';
export const UPDATE_VOUCHERS = 'UPDATE_VOUCHERS';
export const TEMP_ITEM_DATA = 'TEMP_ITEM_DATA';
export const MEMBERS_LIST = 'MEMBERS_LIST';
export const ITEMS_DATA = 'ITEMS_DATA';
export const TAB_SIZE = 1250;
export const FILTER_SIZE = 1110;
// voucher types
export const VOUCHER_TYPE_SALES = 'sales';
export const VOUCHER_TYPE_PURCHASE = 'purchase';
export const VOUCHER_TYPE_EXPENSE = 'expense';
export const VOUCHER_TYPE_CREDIT_NOTE = 'credit_note';
export const VOUCHER_TYPE_DEBIT_NOTE = 'debit_note';
export const VOUCHER_TYPE_RECEIPT = 'receipt';
export const VOUCHER_TYPE_PAYMENT = 'payment';
export const VOUCHER_TYPE_JOURNAL = 'journal';
export const VOUCHER_TYPE_CHALLAN = 'challan';
export const VOUCHER_TYPE_SCHALLAN = 'schallan';
export const VOUCHER_TYPE_PCHALLAN = 'pchallan';
export const VOUCHER_TYPE_LINKED_SCHALLAN = 'linked_schallan';
export const VOUCHER_TYPE_LINKED_PCHALLAN = 'linked_pchallan';
export const VOUCHER_TYPE_LINKED_SESTIMATION = 'linked_sestimation';
export const VOUCHER_TYPE_LINKED_PESTIMATION = 'linked_pestimation';
export const VOUCHER_TYPE_CONTRA = 'contra';
export const VOUCHER_TYPE_SESTIMATION = 'sestimation';
export const VOUCHER_TYPE_PESTIMATION = 'pestimation';
export const VOUCHER_TYPE_STOCK_TRANSFER = 'stock_transfer';
export const VOUCHER_TYPE_STOCK_TRANSFER2 = 'stock-transfer';
export const VOUCHER_TYPE_STOCK_ADJUSTMENT = 'stock_adjustment';
export const VOUCHER_TYPE_STOCK_ADJUSTMENT2 = 'stock-adjustment';
export const VOUCHER_TYPE_STOCK_TYPE = 'stock-type';
export const VOUCHER_TYPE_STOCK_TRANSFER_ADJUSTMENT = 'stock_transfer_adjustment';
export const VOUCHER_TYPE_ALL = 'all';
export const VOUCHER_TYPE_STOCK_ADJUSTMENT_INWARD = 'stock_adjustment_inward';
export const VOUCHER_TYPE_STOCK_ADJUSTMENT_OUTWARD = 'stock_adjustment_outward';
export const VOUCHER_TYPE_RETURN_OF_ITEMS = 'return_of_items';

export const SALES_ESTIMATION = 'Sales Estimation';
export const PURCHASE_ESTIMATION = 'Purchase Estimation';
export const PURCHASE_CHALLAN = 'Purchase Challan';
export const SALES_CHALLAN = 'Sales Challan';
//voucher status
export const VOUCHER_STATUS_PAID = 'paid';
export const VOUCHER_STATUS_UNPAID = 'unpaid';
//Report type
export const GEOGRAPHICAL_REPORT = 'Geographical-report';
export const REPORT_LIST = 'list';
export const TRIAL_BALANCE = 'trial-balance';
export const PROFIT_AND_LOSS = 'profit-and-loss';
export const BALANCE_SHEET = 'balance-sheet';
export const BALANCE_SHEET_TITLE = 'Balance Sheet';
export const INVENTORY = 'inventory';
export const INVENTORY_ALL = 'inventory-all';
export const VENDOR_ALL_PATH = 'inventory-vendor-all';
export const ALL_GROUPS_INVENTORY = 'All Item Groups';
export const ALL_ITEMS_INVENTORY = 'All Items';
export const ALL_VENDORS = 'All Vendor';
export const GSTR1 = 'gstr-1';
export const GSTR1_CR_DR = 'gstr-1-cr-dr';
export const GSTR1_LABEL = 'GSTR 1';
export const GSTR1_EXPORT_SALES = 'Export Sales Register(GSTR 1)';
export const GSTR1_EXPORT_PURCHASE = 'Export Purchase Register(GSTR 2)';
export const ALL_ACCOUNTS = 'all-accounts';
export const DAY_BOOK = 'day-book';
export const SUNDRY_DEBTORS = 'sundry-debtors';
export const SUNDRY_CREDITORS = 'sundry-creditors';
export const PARTIES = 'parties';
export const DUE_LIST_TITLE = 'Payment Dues';
export const DUE_LIST = 'payment-dues';
export const CITIES = 'cities';
export const ACCOUNTS = 'accounts';
export const TAXES = 'duties-and-taxes';
export const EXPENSES = 'indirect-expenses';
export const DIRECT_EXPENSES = 'direct-expenses';
export const STATE_AND_CITY = 'state-city';
export const EXPENSE = 'Expense';
export const RP_VOUCHER = 'RP_VOUCHER';
export const LIABILITIES = 'Liabilities';
export const LIABILITIES_KEY = 'liabilities';
export const REVENUE = 'Revenue';
export const ASSETS = 'Assets';
export const ASSETS_KEY = 'assets';
export const DIRECT_EXPENSE_KEY = 'directExpenses';
export const INDIRECT_EXPENSE_KEY = 'indirectExpenses';
export const DIRECT_REVENUE_KEY = 'directRevenues';
export const INDIRECT_REVENUE_KEY = 'indirectRevenues';
export const PROFIT_AND_LOSS_ACC = 'Profit & Loss A/C';
export const AREA_WISE = 'Area wise';
export const ACC_GRPS = {
  [SUNDRY_DEBTORS]: 'Sundry Debtors',
  [SUNDRY_CREDITORS]: 'Sundry Creditors',
  [PARTIES]: 'Parties',
  [TAXES]: 'Taxes',
  [EXPENSES]: 'Expenses',
  [DIRECT_EXPENSES]: 'Direct Expenses'
};
export const SETTINGS_LABELS = {
  unitSellWholeSalePrice: 'Unit Sell WholeSale Price',
  unitSellRetailPrice: 'Unit Sell Retail Price',
  variantView: 'Variant View: Grouped By Primary Attribute',
  variantViewByItemCode: 'Variant View: Grouped By Item Code',
  defaultView: 'Default View',
  comments: 'Allow user comments',
  rateCode: 'Rate Code',
  itemNumber: 'Item Number',
  itemSkuBarcode: 'Item Sku Barcode',
  itemNumericSkuBarcode: 'Item Numeric Barcode',
  itemName: 'Item Name',
  itemCode: 'Item Code',
  itemAttributeValue: 'Item Attribute Value',
  itemAttributeCode: 'Item Attribute Code',
  itemGroupName: 'Item Group Name',
  itemGroupCode: 'Item Group Code',
  showOutOfStock: 'Disable Selection of Out of Stock Items'
};

export const INITIAL_CATALOGUE_SETTINGS = {
  unitSellWholeSalePrice: false,
  unitSellRetailPrice: true,
  variantView: false,
  comments: true,
  rateCode: false,
  // itemNumber: false,
  itemSkuBarcode: false,
  itemNumericSkuBarcode: true,
  itemName: true,
  itemCode: false,
  itemAttributeValue: false,
  itemAttributeCode: false,
  itemGroupName: false,
  itemGroupCode: false
};

export const UNEXPANDABLE_REPORT_ACC = [
  'Opening Stock',
  'Gross Loss c/o',
  'Gross Loss b/f',
  'Net Loss',
  'Profit & Loss A/C',
  'Difference in opening balances',
  'Closing Stock'
];
export const tagBgColors = [
  '#BDBDBD',
  '#219653',
  '#6FCF97',
  '#2F80ED',
  '#2D9CDB',
  '#56CCF2',
  '#9B51E0',
  '#BB6BD9',
  '#EB5757',
  '#F2994A',
  '#F2C94C'
];
export const CHILD_PATH = 'path2';

//Item
export const SEARCH_SEPARATOR = '-';

export const REGULAR_GST_BUSINESS = 'regular';
export const REGULAR_GST_BUSINESS_VALUE = 'Regular GST Business';
export const UNREGISTERED_BUSINESS = 'unregistered';
export const UNREGISTERED_BUSINESS_VALUE = 'Unregistered Business';
export const COMPOSITION_GST_BUSINESS = 'composite';
export const COMPOSITION_GST_BUSINESS_VALUE = 'Composition GST Business';
export const IS_BASIC_TYPE = 'isBasicType';
export const ITEM_FORM = 'ITEM_FORM';
export const FILTER_BY_PURCHASE = 'pur';
export const FILTER_BY_WSP = 'wsp';
export const FILTER_BY_RSP = 'rsp';

//Party
export const PARTY_PROFILE = 'partyProfile';
export const PARTY_SELECT_LIST = 'partySelectList';
export const PARTY_LIST_DATA = 'partyListData';
export const PARTY_SELECT_DIALOG_TAGS = 'partySelectTags';
export const PARTY_SELECTED_TAGS = 'partySelectedTags';
//Modal related
export const BRANCH_ADDRESS = 'openBranchAddress';
export const COMPANY_BANK_ACCOUNT = 'openCompanyBankAccount';
export const DEL_COMPANY_BANK_ACCOUNT = 'openDelCompanyBankAccount';
export const CHANGE_COMPANY_PASSWORD = 'openChangeCompanyPass';
export const VIEW_ROLE_CONFIG = 'openViewRoleConfig';
export const DELETE_BRANCH = 'openDeleteBranch';
export const DELETE_BANK_ACCOUNT = 'openDeleteBankAccount';
export const TEAM_ROLES = 'openTeamRoles';
export const EDIT_ROLE = 'openEditRole';
export const COMPANY_DETAILS = 'openCompanyDetails';
export const COMPANY_ADD_ACCOUNT = 'openCompanyAddAccount';
export const SALES_DISCOUNT_MODAL = 'openSalesDiscount';
export const PURCHASE_DISCOUNT_MODAL = 'openPurchaseDiscount';
export const ADD_PARTY_MODAL = 'openPartyModal';
export const ADD_PARTY_TRANSPORTER_MODAL = 'openPartyTransporterModal';
export const ADD_SALES_PERSON_MODAL = 'openSalesPersonModal';
export const GROUPWISE_DISCOUNT_MODAL = 'openExclusiveDiscount';
export const EDIT_EXCLUSIVE_DISCOUNT_MODAL = 'openEditExclusiveDiscount';
export const EDIT_CREDIT_DAYS_MODAL = 'openEditCreditDays';
export const CONST_EDIT_OPEN_CONTACT = 'openEditContactModal';
export const INTERNAL_NOTES_DRAWER = 'openInternalNotesDrawer';
export const CATALOGUE_MODAL = 'openItemCatalogueModal';
export const CATALOGUE_ITEMS_MODAL = 'openCatalogueItemsModal';
export const CATALOGUE_RENAME_MODAL = 'openCatalogueRenameModal';
export const CATALOGUE_SETTINGS_MODAL = 'openCatalogueSettingsModal';
export const REMOVE_BUSINESS_ADMIN_MODAL = 'removeBusinessAdminModal';
export const CATALOGUE_ITEM_POSITION_MODAL = 'openCatalogueItemPositionModal';
export const ITEM_CATALOGUE_MODAL = 'openItemCatalogueModal';
export const CREATE_I_SHOP_MODAL = 'openCreateIShopModal';
export const EXPORT_REPORT_MODAL = 'openExportReportModal';
export const CONTACT_BOOK_MODAL = 'openContactBookModal';
export const GROUP_CODE_MAP_MODAL = 'openGroupCodeMapModal';
export const ISHOP_SETTINGS_MODAL = 'openIShopSettingsModal';
export const ADD = 'add';
export const REMOVE = 'remove';
export const DELETE = 'delete';
export const DELETE_MAIN = 'deleteMain';
export const ASCENDING = 'ASC';
export const DESCENDING = 'DESC';
export const TABLE_ASCENDING = 'asc';
export const DEFAULT_TABLE_SORTING_KEY = 'sorting';
export const BUSINESSES = 'businesses';
export const CATALOGUE_ITEM_TABLE = 'catalogueItemsTable';
export const ADD_CATALOGUE_ITEM_TABLE = 'addCatalogueItemsTable';
export const GROUPED_ITEMS_TABLE = 'groupedItemsTable';
export const GROUPED_ITEM_VARIANTS_TABLE = 'groupedItemVariantsTable';
export const GROUPED_ITEMS_VARIANTS_TABLE = 'groupedItemsVariantsTable';
export const FILTERS_KEY = 'filters';
export const CATALOGUE_FILTERS_KEY = 'catalogueFilters';
export const ITEM_VARIANT_FILTER_KEY = 'itemVariantFilters';
export const HIDDEN_ITEM_FILERS_KEY = 'hiddenItemFilters';
export const HIDDEN_ITEM_LIST = 'hiddenItemList';

//Company related
export const GOLD_JEWLERY = 'Gold Jewlery';
export const IMITATION_JEWLERY = 'Imitation Jewlery';

export const CASH_IN_HAND = 'primary/current-assets/cash-in-hand';
export const BANK_ACCOUNTS = 'primary/current-assets/bank-accounts';
//Snackbar Variants
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const CREDIT = 'credit';
export const DEBIT = 'debit';

//Table related
export const PAGINATION_TRIGGER_COUNT = 5;
export const DEFAULT_TABLE_ID = 'react-grid-table';
export const BULK_EDIT_TABLE = 'bulk-edit-table';
export const VOUCHER_LIST = 'VOUCHER_LIST';
export const VOUCHER_LINE_ITEMS_LIST = 'VOUCHER_LINE_ITEMS_LIST';
//Voucher related
export const SCHALLAN_EMPTY_LINKED_LIST_MESSAGE =
  "You currently don't have any Challans Linked to Sales Voucher";
export const PCHALLAN_EMPTY_LINKED_LIST_MESSAGE =
  "You currently don't have any Phallans Linked to Purchase Voucher";
export const SESTIMATION_EMPTY_LINKED_LIST_MESSAGE =
  "You currently don't have any Estimation Voucher Linked to Sales Voucher";
export const PESTIMATION_EMPTY_LINKED_LIST_MESSAGE =
  "You currently don't have any Estimation Voucher Linked to Sales Voucher";
export const CURRENCY_DECIMAL_ROUND = 2;
export const QTY_DECIMAL_ROUND = 4;
export const PERCENT = '%';
export const RUPEES = 'Rs.';
export const RUPEES_VALUE = 'Rupees';
export const OTHER_CHARGES = 'otherCharges';
export const OTHER_CHARGES_AFTER_TAX = 'otherChargesAfterTax';

export const VOUCHER_DETAILS_TYPE = 'VOUCHER_DETAILS_TYPE';
export const PARTY = 'party';
export const ACCOUNT = 'account';
export const PATH = 'path';

export const EDIT_NAME = 'openEditName';
export const EDIT_BALANCE = 'openEditBalance';
export const PURCHASE_PRICE = 'PurchasePrice';
export const WHOLESALE_PRICE = 'WholesalePrice';
export const RETAIL_PRICE = 'RetailPrice';

export const VOUCHER_PARTY_PICKER = 'VoucherPartyPicker';
export const ADD_TRANSPORTER_TAB_PARTY_PICKER = 'AddTransporterTabPartyPicker';
export const EDIT_TRANSPORTER_TAB_PARTY_PICKER = 'EditTransporterTabPartyPicker';
export const SELECTIVE = 'SELECTIVE';
export const PAGE_ALL = 'PAGE_ALL';

export const RECONCILED = 'reconciled';
export const UNRECONCILED = 'unreconciled';
export const INPROGRESS = 'in-progress';
export const INWARD = 'inward';
export const OUTWARD = 'outward';

export const VOUCHER_VIEW_MODE_KEY = 'VOUCHER';
//voucher calc related actions
export const PARTY_SELECT = 'PARTY_SELECT';
export const NEW_LINE = 'NEW_LINE';
export const EDIT_LINE = 'EDIT_LINE';
export const DELETE_LINE = 'DELETE_LINE';
export const REPLACE_LINE = 'REPLACE_LINE';
export const SET_COUPON_DISCOUNT = 'SET_COUPON_DISCOUNT';
export const SET_ADDITIONAL_DISCOUNT = 'SET_ADDITIONAL_DISCOUNT';
export const RECALCULATE = 'RECALCULATE';
export const SET_BRANCH = 'SET_BRANCH';
export const EDIT_OTHER_CHARGES_AFTER_TAX = 'EDIT_OTHER_CHARGES_AFTER_TAX';
export const EDIT_OTHER_CHARGES = 'EDIT_OTHER_CHARGES';
export const MERGE_ESTIMATION = 'MERGE_ESTIMATION';
export const SET_ROUNDOFF = 'setRoundOffValue';
export const SET_EXCLUSIVE_DISCOUNT = 'SET_EXCLUSIVE_DISCOUNT';
export const SUNDRY_CREDITOR_PATH = 'primary/current-liabilities/sundry-creditors';
export const SUNDRY_DEBITOR_PATH = 'primary/current-assets/sundry-debtors';

export const WEEK = 'week';
export const YEAR = 'year';
export const MONTH = 'month';
export const DAY = 'day';
export const TODAY = 'today';
export const CUSTOM = 'custom';

export const ADD_DUPLICATE = 'addDuplicate';
export const SHOW_DUPLICATE_DIALOG = 'showDuplicateDialog';
export const UPDATE_QTY = 'updateItemQty';
export const INPUT_CHANGE = 'input-change';

export const CONST_OPEN_CONTACT = 'openContactModal';
export const CONST_MARK_PRIMARY = 'openMarkPrimary';
export const DELETE_CONTACT = 'openDeleteContact';
export const ADD_SECOND = 'addSecondary';
export const CONST_UPD_PRIMARY = 'updatePrimary';
export const CONST_UPD_SECONDARY = 'updateSecondary';
export const CONST_REPLACE_PRIMARY = 'replacePrimary';

export const REPORTS_BASE_PATH = '/:id/home/:page/:type';
export const INVENTORY_ALL_ITEMS_PATH = '/:id/home/:page/' + INVENTORY_ALL;
export const INVENTORY_ITEMS_PATH = '/:id/home/:page/:type/item-group/:itemGroupId';
export const INVENTORY_MONTHS_PATH =
  '/:id/home/:page/:type/item-group/:itemGroupId/item/:itemId/monthly';
export const INVENTORY_ITEM_STATEMENT_PATH =
  '/:id/home/:page/:type/item-group/:itemGroupId/item/:itemId';
export const INVENTORY_VENDOR_WISE_PATH = '/:id/home/reports/inventory-vendor-all';
export const REPORT_SEARCH_KEY = 'searchText';
export const REPORT_BARCODE_TOGGLE_KEY = 'barcodeSearchOn';
export const INVENTORY_ITEM_GROUP_SEARCH_KEY = 'itemGroupSearchText';
export const INVENTORY_ITEM_NAME_SEARCH_KEY = 'itemSearchText';
export const STATEMENT_LIST_PATH = '/:id/home/:page/:type/account/:accountId';
export const YEARLY_LIST_VIEW =
  '/:id/home/:page/:type/account/:accountId/date/:monthStart/:monthEnd';
export const PATH_LIST_PATH = '/:id/home/:page/:type/path';
export const PATH_STATEMENT_LIST_PATH = '/:id/home/:page/:type/path/account/:accountId';
export const PATH_STATEMENT_VOUCHER_LIST_PATH =
  '/:id/home/:page/:type/path/account/:accountId/date/:monthStart/:monthEnd';

export const DEBIT_SHORT = 'Dr';
export const CREDIT_SHORT = 'Cr';
export const PATH_MONTH_LIST_PATH = '/:id/home/:page/:type/path/monthly/:accountId';
export const PATH_MONTH_VOUCHER_LIST_PATH =
  '/:id/home/:page/:type/path/monthly/:accountId/date/:monthStart/:monthEnd';

export const TABLE_COL_WIDTH_MULT = 0.02;

export const SUNDRY_DEBTORS_TYPE = 'Sundry Debtors';
export const SUNDRY_CREDITORS_TYPE = 'Sundry Creditors';
export const BANK_ACCOUNTS_TYPE = 'Bank Accounts';
export const ON_ACCOUNT = 'onAccount';
export const AGAINST_VOUCHER = 'againstVoucher';
export const voucherSearchTypeList = [
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_CHALLAN,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_PESTIMATION,
  VOUCHER_TYPE_CREDIT_NOTE,
  VOUCHER_TYPE_DEBIT_NOTE,
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_PAYMENT,
  VOUCHER_TYPE_JOURNAL,
  VOUCHER_TYPE_CONTRA,
  VOUCHER_TYPE_EXPENSE
];

export const voucherConvertList = [
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_CHALLAN,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_PESTIMATION
];

//Guide related
export const RP_ADD_GUIDE = 'rpGuide';
export const PU_ADD_GUIDE = 'puGuide';
export const PU_LINE_ITEM_GUIDE = 'puLineItemGuide';
export const DASHBOARD_GUIDE = 'dashboard-guide';
export const WELCOME_GUIDE = 'welcome-guide';
export const ITEM_MESSAGE = 'itemBasicAdvancedMessage';

//Item code
export const ITEM_CODE_SEPERATOR = '-';
export const ITEM_NAME_SEPERATOR = ' ';
export const LATEST = 'latest';
export const ITEM = 'ITEM';
export const ITEM_GROUP = 'ITEM_GROUP';

export const RECONCILE_MODAL_PAGE_SIZE = 20;

export const BUSINESS_ADMIN = 'business-admin';

export const UNIT_CONVERSION_CONST = {
  pcs: 1,
  doz: 12,
  gro: 144
};

//Print related
export const BARCODE_PRINT = 'barcode';

export const VOUCHER_SETTINGS_LIST = [
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_CREDIT_NOTE,
  VOUCHER_TYPE_DEBIT_NOTE,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_LINKED_SCHALLAN,
  VOUCHER_TYPE_LINKED_PCHALLAN,
  VOUCHER_TYPE_LINKED_SESTIMATION,
  VOUCHER_TYPE_LINKED_PESTIMATION,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_PESTIMATION,
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  VOUCHER_TYPE_CONTRA,
  VOUCHER_TYPE_JOURNAL,
  VOUCHER_TYPE_EXPENSE
];

export const VOUCHER_DEFAULT_COLUMN_VISIBILITY = {
  index: true,
  srNoByGroup: true,
  itemName: true,
  numericSkuBarcode: true,
  taxPercentage: true,
  partyDesignCode: false,
  description: false,
  boxQty: false,
  boxNo: true,
  qtyPerBox: false,
  inStoreQty: true,
  qty: true,
  unit: true,
  unitPrice: true,
  discountValue: false,
  lineAmount: true,
  wsp: true,
  rsp: true,
  rateCode: true,
  actions: true
};

export const AJ = 'aj';
export const TALLY = 'tally';
export const NEW_WAY_PRINT_COMPANIES = ['5d2eb70ce6e46c0014bb7af9'];
export const EXCLUDE_DUE_DATE = ['contra', 'journal'];
export const ITEM_GROUPCODE_DOT_ERRMSG = 'dot is not allowed in group code';

export const SKU_BAR_CODE = 'skuBarCode';
export const SKU_BARCODE_VERSION3 = 'version3';
export const INCLUDE_PARTY_CODE = 'includePartyCode';
export const INCLUDE_ITEM_LABEL = 'includeItemLabel';
export const INCLUDE_ITEM_ATTRIBUTES_AND_LABEL = 'includeItemAttributesAndLabel';
export const EXCLUDE_ZERO_BALANCE_ITEM_GROUP = 'excludeZeroBalanceItemGroup';
export const ITEM_GROUP_REPORTS_PATH = 'item-group';
export const INCLUDE_TALLY_PERCENTAGE = 'includePercentageBsPl';
export const INCLUDE_DISCOUNT_AND_TAX = 'includeDiscountAndTax';
// MR ICompany ID
export const MR_COMPANY_ID = '61c189bebb56ad0015f41881';
export const UPLOAD_FILE_TO_SERVER = ['61c189bebb56ad0015f41881'];

export const EINVOICEING = 'Einvoicing';
export const EWAY_BILL = 'EwayBill';
export const EINVOICE = 'E-Invoice';
export const SELECTED_VOUCHER_FINYEAR_ERROR = 'Selected Voucher is not of Choosen Financial year';
// Einvoice and Ewaybill is not available
export const EINVOICE_EWAYBILL_NA = 'Voucher is not available';
export const EINVOICE_CANCEL_REASON_LIST = [
  'Duplicate', // stands for 1
  'Data Entry Mistake' // stands for 2
];

export const EWAY_BILL_CANCEL_REASON_LIST = [
  'Duplicate', // stands for 1
  'Cancelled', // stands for 2
  'Data Entry Mistake', // stands for 3
  'Others' // stands for 4
];
// Plan expiry message
export const PLAN_EXPIRED_ALREADY_MESSAGE =
  'Your plan has expired. Kindly clear all your dues to continue using the software.';
export const PLAN_EXPIRED_TODAY_MESSAGE =
  'Your plan will expire Today. Kindly clear all your dues to continue using the software.';

export const ALLOW_DUPLICATE_ITEM_WARNING =
  'Are you sure you want to allow duplicate item?. After saving, you will not be able to revert the settings.';
export const SETTING_NOT_REVERT = 'You will not be able to change the settings.';

// printing service
export const PDF_GENERATOR = 'pdfGenerator';
export const INVOCK_PRINTING = 'invockPrinting';

export const BILL_TO = 'Bill To';
export const ACCOUNT_TYPE = 'Account Type';
export const SHIP_TO = 'Ship To';
export const TRANSACTION_TYPE = 'Transaction Details';
export const SALES_PERSON_ = 'Sales Person';
export const TRANSPORT = 'Transport';
export const PICK_UP_LOCATION = 'Pick Up Location';

export const SALES = 'Sales';
export const PURCHASE = 'Purchase';

// account group
export const ADD_METHOD = 'add';
export const LESS_METHOD = 'less';
export const ADD_LABEL = 'Add';
export const LESS_LABEL = 'Less';
export const MULTIPLE = 'MULTIPLE';
export const ALL = 'all';
export const BARCODE_SEARCH_ERROR_MSG = 'Only Barcode number is allowed';
export const CREDIT_DEBIT_REPORT = 'cdnr';
export const UNREGISTER_CREDIT_DEBIT_REPORT = 'cdnur';
export const SEARCH_TEXT = 'searchText';
export const INDIRECT_EXPENSE = 'Indirect Expense';
export const LINE_ITEM = 'lineItem';

// pos
export const WHOLESALE_PRICE_LABEL = 'Wholesale Price';
export const PURCHASE_PRICE_LABEL = 'Purchase Price';
export const RETAIL_PRICE_LABEL = 'Retail Price';
export const GROUPED_UNIT = 'Grouped Unit';
export const VENDOR_DESIGN_CODE = 'Vendor Design Code';
export const ITEM_GROUP_LABEL = 'Item Group';
export const ATTRIBUTE_1 = 'Attribute 1';
export const ATTRIBUTE_2 = 'Attribute 2';
export const ATTRIBUTE_3 = 'Attribute 3';
export const DESCRIPTION = 'Description';
export const SHOW_ISHOP = 'IShop';
export const LOW_STOCK_ALERT_QTY = 'Low Stock Alert Qty';

export const SKU_PRINT_VIEW_TABLE = 'sku-print-view-table';
export const EMPTY_COL_VALUE = '';
export const ADJUSTMENT_ON_ITEM_PRICE = 'adjustment_on_item_price';
export const RETURN_OF_ITEMS = 'return of items';
export const ADJUSTMENT_ON_ITEM_PRICE_LABEL = 'Adjustment on item price';

export const SUB_TYPE_MAPPER = {
  [VOUCHER_TYPE_LINKED_PESTIMATION]: VOUCHER_TYPE_PESTIMATION,
  [VOUCHER_TYPE_LINKED_SESTIMATION]: VOUCHER_TYPE_SESTIMATION,
  [VOUCHER_TYPE_LINKED_PCHALLAN]: VOUCHER_TYPE_PCHALLAN,
  [VOUCHER_TYPE_LINKED_SCHALLAN]: VOUCHER_TYPE_SCHALLAN,
  [VOUCHER_TYPE_RETURN_OF_ITEMS]: RETURN_OF_ITEMS,
  [ADJUSTMENT_ON_ITEM_PRICE]: ADJUSTMENT_ON_ITEM_PRICE_LABEL
};

export const DUTIES_AND_TAXES = 'Duties And Taxes';
export const OPEN_QUICK_PARTY_CREATION_MODAL = 'openQuickPartyCreationModal';

export const SEARCH_INPUT_THRESHOLD = 500;

export const TRANSPORT_MODE = [
  {
    label: 'Road',
    value: 'Road'
  },
  {
    label: 'Air',
    value: 'Air'
  },
  {
    label: 'Ship',
    value: 'Ship'
  },
  {
    label: 'Rail',
    value: 'Rail'
  }
];

export const CATALOG_ISHOP_PREDEFINED_PARAMETERS = [
  'ic',
  'ib',
  'catalogueName',
  'customerName',
  'companyName',
  'phone',
  'maxCartLimit',
  'minCartLimit',
  'message',
  'action',
  'voucherNo',
  'name',
  'stopSharingToWhatsapp',
  'hideItemGroupFilter',
  'hiddenFilterList'
];
export const VARIANT_VIEW = 'variantView';
export const VARIANT_VIEW_BY_ITEM_CODE = 'variantViewByItemCode';
export const DEFAULT_VIEW = 'defaultView';
