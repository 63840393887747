// User Specific

export const OTP_REQUESTED = 'OTP_REQUESTED';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAILED = 'OTP_FAILED';

export const OTP_SIGN_REQUESTED = 'OTP_SIGN_REQUESTED';
export const OTP_SIGN_SUCCESS = 'OTP_SIGN_SUCCESS';
export const OTP_SIGN_FAILED = 'OTP_SIGN_FAILED';

export const OTP_VERIFICATION_REQUESTED = 'OTP_VERIFICATION_REQUESTED';
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED';

export const SET_USER_NAME_REQUESTED = 'SET_USER_NAME_REQUESTED';
export const SET_USER_NAME_SUCCESS = 'SET_USER_NAME_SUCCESS';
export const SET_USER_NAME_FAILED = 'SET_USER_NAME_FAILED';

export const UPDATE_USER_NAME_REQUESTED = 'UPDATE_USER_NAME_REQUESTED';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAILED = 'UPDATE_USER_NAME_FAILED';

export const UPDATE_USER_GUIDE_STATUS_SUCCESS = 'UPDATE_USER_GUIDE_STATUS_SUCCESS';
export const UPDATE_USER_GUIDE_STATUS_FAILED = 'UPDATE_USER_GUIDE_STATUS_FAILED';

export const GET_USER_GUIDE_STATUS_SUCCESS = 'GET_USER_GUIDE_STATUS_SUCCESS';
export const GET_USER_GUIDE_STATUS_FAILED = 'GET_USER_GUIDE_STATUS_FAILED';

export const UPDATE_USER_EMAIL_REQUESTED = 'UPDATE_USER_EMAIL_REQUESTED';
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_FAILED = 'UPDATE_USER_EMAIL_FAILED';

export const GET_USER_REFERRALS_REQUESTED = 'GET_USER_REFERRALS_REQUESTED';
export const GET_USER_REFERRALS_SUCCESS = 'GET_USER_REFERRALS_SUCCESS';
export const GET_USER_REFERRALS_FAILED = 'GET_USER_REFERRALS_FAILED';

export const PASSWORD_RESET_OTP_REQUESTED = 'PASSWORD_RESET_OTP_REQUESTED';
export const PASSWORD_RESET_OTP_SUCCESS = 'PASSWORD_RESET_OTP_SUCCESS';
export const PASSWORD_RESET_OTP_FAILED = 'PASSWORD_RESET_OTP_FAILED';

export const REMOVE_BUSINESS_ADMIN_OTP_REQUESTED = 'REMOVE_BUSINESS_ADMIN_OTP_REQUESTED';
export const REMOVE_BUSINESS_ADMIN_OTP_SUCCESS = 'REMOVE_BUSINESS_ADMIN_OTP_SUCCESS';
export const REMOVE_BUSINESS_ADMIN_OTP_FAILED = 'REMOVE_BUSINESS_ADMIN_OTP_FAILED';

export const PASSWORD_RESET_VERIFICATION_REQUESTED = 'PASSWORD_RESET_VERIFICATION_REQUESTED';
export const PASSWORD_RESET_VERIFICATION_SUCCESS = 'PASSWORD_RESET_VERIFICATION_SUCCESS';
export const PASSWORD_RESET_VERIFICATION_FAILED = 'PASSWORD_RESET_VERIFICATION_FAILED';

export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';

export const UPDATE_USER_COMPANY_SETTING = 'UPDATE_USER_COMPANY_SETTING';
export const UPDATE_USER_COMPANY_VOUCHER_SETTING = 'UPDATE_USER_COMPANY_VOUCHER_SETTING';

export const FETCH_COMPANY_SETTINGS_SUCCESS = 'FETCH_COMPANY_SETTINGS_SUCCESS';
export const SET_COMPANY_VOUCHER_SETTINGS = 'SET_COMPANY_VOUCHER_SETTINGS';

export const DELETE_USER_ACCESS_TOKEN_REQUESTED = 'DELETE_USER_ACCESS_TOKEN_REQUESTED';
export const DELETE_USER_ACCESS_TOKEN_SUCCESS = 'DELETE_USER_ACCESS_TOKEN_SUCCESS';
export const DELETE_USER_ACCESS_TOKEN_FAILED = 'DELETE_USER_ACCESS_TOKEN_FAILED';

export const AUTHENTICATE_USER_TOKEN_REQUESTED = 'AUTHENTICATE_USER_TOKEN_REQUESTED';
export const AUTHENTICATE_USER_TOKEN_SUCCESS = 'AUTHENTICATE_USER_TOKEN_SUCCESS';
export const AUTHENTICATE_USER_TOKEN_FAILED = 'AUTHENTICATE_USER_TOKEN_FAILED';

export const REFRESH_USER_TOKEN_REQUESTED = 'REFRESH_USER_TOKEN_REQUESTED';
export const REFRESH_USER_TOKEN_SUCCESS = 'REFRESH_USER_TOKEN_SUCCESS';
export const REFRESH_USER_TOKEN_FAILED = 'REFRESH_USER_TOKEN_FAILED';
export const USER_TAB_LOGOUT = 'USER_TAB_LOGOUT';
export const USER_LOGOUT = 'USER_LOGOUT';
// Company Specific

export const SET_UPDATE_CACHED_API = 'SET_UPDATE_CACHED_API';
export const HANDLE_CACHE_API = 'HANDLE_CACHE_API';
export const SET_CACHE_API_LIST = 'SET_CACHE_API_LIST';
export const PDF_TEMPLATES_REQUESTED = 'PDF_TEMPLATES_REQUESTED';
export const PDF_TEMPLATES_SUCCESS = 'PDF_TEMPLATES_SUCCESS';

export const SET_PARTY_UPLOADING_FLAG = 'SET_PARTY_UPLOADING_FLAG';

export const COMPANIES_LIST_REQUESTED = 'COMPANIES_LIST_REQUESTED';
export const COMPANIES_LIST_SUCCESS = 'COMPANIES_LIST_SUCCESS';
export const COMPANIES_LIST_FAILED = 'COMPANIES_LIST_FAILED';
export const ADD_COMPANIES_LIST = 'ADD_COMPANIES_LIST';

export const FETCH_COMPANY_TEMPLATE_LIST = 'FETCH_COMPANY_TEMPLATE_LIST';

export const CHANGE_COMPANY = 'CHANGE_COMPANY';

export const COMPANY_AUTH_REQUESTED = 'COMPANY_AUTH_REQUESTED';
export const COMPANY_AUTH_SUCCESS = 'COMPANY_AUTH_SUCCESS';
export const COMPANY_AUTH_FAILED = 'COMPANY_AUTH_FAILED';
export const COMPANY_SIGNOUT = 'COMPANY_SIGNOUT';

export const COMPANY_MEMBERS_REQUESTED = 'COMPANY_MEMBERS_REQUESTED';
export const COMPANY_MEMBERS_SUCCESS = 'COMPANY_MEMBERS_SUCCESS';
export const COMPANY_MEMBERS_FAILED = 'COMPANY_MEMBERS_FAILED';

export const ACCOUNTS_NAME = 'ACCOUNTS_NAME';

export const COMPANY_BRANCH_REQUESTED = 'COMPANY_BRANCH_REQUESTED';

export const COMPANY_ADD_BRANCH_REQUESTED = 'COMPANY_ADD_BRANCH_REQUESTED';
export const COMPANY_ADD_BRANCH_SUCCESS = 'COMPANY_ADD_BRANCH_SUCCESS';
export const COMPANY_ADD_BRANCH_FAILED = 'COMPANY_ADD_BRANCH_FAILED';

export const COMPANY_UPDATE_BRANCH_REQUESTED = 'COMPANY_UPDATE_BRANCH_REQUESTED';
export const COMPANY_UPDATE_BRANCH_SUCCESS = 'COMPANY_UPDATE_BRANCH_SUCCESS';
export const COMPANY_UPDATE_BRANCH_FAILED = 'COMPANY_UPDATE_BRANCH_FAILED';

export const COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED = 'COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED';
export const COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS = 'COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS';
export const COMPANY_FETCH_BANK_ACCOUNTS_FAILED = 'COMPANY_FETCH_BANK_ACCOUNTS_FAILED';
export const COMPANY_ADD_BANK_ACCOUNT_REQUESTED = 'COMPANY_ADD_BANK_ACCOUNT_REQUESTED';
export const COMPANY_ADD_BANK_ACCOUNT_SUCCESS = 'COMPANY_ADD_BANK_ACCOUNT_SUCCESS';
export const COMPANY_ADD_BANK_ACCOUNT_FAILED = 'COMPANY_ADD_BANK_ACCOUNT_FAILED';
export const COMPANY_UPDATE_BANK_ACCOUNT_REQUESTED = 'COMPANY_UPDATE_BANK_ACCOUNT_REQUESTED';
export const COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS = 'COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS';
export const COMPANY_UPDATE_BANK_ACCOUNT_FAILED = 'COMPANY_UPDATE_BANK_ACCOUNT_FAILED';

export const COMPANY_PASSWORD_CHANGE_REQUESTED = 'COMPANY_PASSWORD_CHANGE_REQUESTED';
export const COMPANY_PASSWORD_CHANGE_SUCCESS = 'COMPANY_PASSWORD_CHANGE_SUCCESS';
export const COMPANY_PASSWORD_CHANGE_FAILED = 'COMPANY_PASSWORD_CHANGE_FAILED';

export const COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED =
  'COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED';
export const COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS =
  'COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS';
export const COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED =
  'COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED';

export const COMPANY_SET_E_SHOP_STATUS_REQUESTED = 'COMPANY_SET_E_SHOP_STATUS_REQUESTED';
export const COMPANY_SET_E_SHOP_STATUS_SUCCESS = 'COMPANY_SET_E_SHOP_STATUS_SUCCESS';
export const COMPANY_SET_E_SHOP_STATUS_FAILED = 'COMPANY_SET_E_SHOP_STATUS_FAILED';

export const COMPANY_UPDATE_E_SHOP_NAME_REQUESTED = 'COMPANY_UPDATE_E_SHOP_NAME_REQUESTED';
export const COMPANY_UPDATE_E_SHOP_NAME_SUCCESS = 'COMPANY_UPDATE_E_SHOP_NAME_SUCCESS';
export const COMPANY_UPDATE_E_SHOP_NAME_FAILED = 'COMPANY_UPDATE_E_SHOP_NAME_FAILED';

export const COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED =
  'COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED';
export const COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS = 'COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS';
export const COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED = 'COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED';

export const COMPANY_DELETE_USER_SESSION_REQUESTED = 'COMPANY_DELETE_USER_SESSION_REQUESTED';
export const COMPANY_DELETE_USER_SESSION_SUCCESS = 'COMPANY_DELETE_USER_SESSION_SUCCESS';
export const COMPANY_DELETE_USER_SESSION_FAILED = 'COMPANY_DELETE_USER_SESSION_FAILED';

export const COMPANY_ADD_ACCOUNT_REQUESTED = 'COMPANY_ADD_ACCOUNT_REQUESTED';
export const COMPANY_ADD_ACCOUNT_SUCCESS = 'COMPANY_ADD_ACCOUNT_SUCCESS';
export const COMPANY_ADD_ACCOUNT_FAILED = 'COMPANY_ADD_ACCOUNT_FAILED';
export const COMPANY_ADD_ACCOUNT_FIELD_METADATA = 'COMPANY_ADD_ACCOUNT_FIELD_METADATA';

export const COMPANY_FETCH_EXPENSE_ACCOUNTS_REQUESTED = 'COMPANY_FETCH_EXPENSE_ACCOUNTS_REQUESTED';
export const COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS =
  'COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS';
export const COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_FAILED =
  'COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_FAILED';

export const COMPANY_FETCH_ROLE_CONFIG_SUCCESS = 'COMPANY_FETCH_ROLE_CONFIG_SUCCESS';
export const COMPANY_FETCH_ROLE_CONFIG_FAILED = 'COMPANY_FETCH_ROLE_CONFIG_FAILED';
export const COMPANY_ROLE_CONFIG_REQUESTED = 'COMPANY_ROLE_CONFIG_REQUESTED';
export const COMPANY_ADD_MEMBER_ROLE_REQUESTED = 'COMPANY_ADD_MEMBER_ROLE_REQUESTED';
export const COMPANY_ADD_MEMBER_ROLE_SUCCESS = 'COMPANY_ADD_MEMBER_ROLE_SUCCESS';
export const COMPANY_ADD_MEMBER_ROLE_FAILED = 'COMPANY_ADD_MEMBER_ROLE_FAILED';
export const COMPANY_REMOVE_MEMBER_ROLE_REQUESTED = 'COMPANY_REMOVE_MEMBER_ROLE_REQUESTED';
export const COMPANY_REMOVE_MEMBER_ROLE_SUCCESS = 'COMPANY_REMOVE_MEMBER_ROLE_SUCCESS';
export const COMPANY_REMOVE_MEMBER_ROLE_FAILED = 'COMPANY_REMOVE_MEMBER_ROLE_FAILED';
export const COMPANY_FETCH_TEAM_ROLE_REQUESTED = 'COMPANY_FETCH_TEAM_ROLE_REQUESTED';
export const COMPANY_FETCH_TEAM_ROLE_SUCCESS = 'COMPANY_FETCH_TEAM_ROLE_SUCCESS';
export const COMPANY_FETCH_TEAM_ROLE_FAILED = 'COMPANY_FETCH_TEAM_ROLE_FAILED';

export const COMPANY_UPDATE_DETAILS_REQUESTED = 'COMPANY_UPDATE_DETAILS_REQUESTED';
export const COMPANY_UPDATE_DETAILS_SUCCESS = 'COMPANY_UPDATE_DETAILS_SUCCESS';
export const COMPANY_UPDATE_DETAILS_FAILED = 'COMPANY_UPDATE_DETAILS_FAILED';
export const COMPANY_DELETE_BRANCH_REQUESTED = 'COMPANY_DELETE_BRANCH_REQUESTED';
export const COMPANY_DELETE_BRANCH_SUCCESS = 'COMPANY_DELETE_BRANCH_SUCCESS';
export const COMPANY_DELETE_BRANCH_FAILED = 'COMPANY_DELETE_BRANCH_FAILED';

export const TOGGLE_BRANCH_SETTING_MODAL = 'TOGGLE_BRANCH_SETTING_MODAL';

export const COMPANY_DELETE_TEAM_MEMBER_REQUESTED = 'COMPANY_DELETE_TEAM_MEMBER_REQUESTED';
export const COMPANY_DELETE_TEAM_MEMBER_SUCCESS = 'COMPANY_DELETE_TEAM_MEMBER_SUCCESS';
export const COMPANY_DELETE_TEAM_MEMBER_FAILED = 'COMPANY_DELETE_TEAM_MEMBER_FAILED';

export const COMPANY_RESET_ROLES_REQUESTED = 'COMPANY_RESET_ROLES_REQUESTED';
export const COMPANY_RESET_ROLES_SUCCESS = 'COMPANY_RESET_ROLES_SUCCESS';
export const COMPANY_RESET_ROLES_FAILED = 'COMPANY_RESET_ROLES_FAILED';

export const COMPANY_DELETE_BANK_ACCOUNT_REQUESTED = 'COMPANY_DELETE_BANK_ACCOUNT_REQUESTED';
export const COMPANY_DELETE_BANK_ACCOUNT_SUCCESS = 'COMPANY_DELETE_BANK_ACCOUNT_SUCCESS';
export const COMPANY_DELETE_BANK_ACCOUNT_FAILED = 'COMPANY_DELETE_BANK_ACCOUNT_FAILED';

export const COMPANY_FETCH_TEAM_REQUESTED = 'COMPANY_FETCH_TEAM_REQUESTED';
export const COMPANY_FETCH_TEAM_SUCCESS = 'COMPANY_FETCH_TEAM_SUCCESS';
export const COMPANY_FETCH_TEAM_FAILED = 'COMPANY_FETCH_TEAM_FAILED';
export const COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS = 'COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS';
export const COMPANY_FETCH_ACCOUNT_GROUP_FAILED = 'COMPANY_FETCH_ACCOUNT_GROUP_FAILED';
// export const COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS = 'COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS';
// export const COMPANY_FETCH_ALL_ACCOUNTS_FAILED = 'COMPANY_FETCH_ALL_ACCOUNTS_FAILED';
export const LOAD_NEW_RECONCILE_AMOUNT = 'LOAD_NEW_RECONCILE_AMOUNT';
export const RECONCILATION_INITIATED = 'RECONCILATION_INITIATED';
export const RECONCILATION_SUCCESS = 'RECONCILATION_SUCCESS';
export const RECONCILATION_FAILED = 'RECONCILATION_FAILED';

// Items Specific

export const COMPANY_ITEMS_REQUESTED = 'COMPANY_ITEMS_REQUESTED';
export const COMPANY_ITEMS_SUCCESS = 'COMPANY_ITEMS_SUCCESS';
export const SHOW_ITEM_GROUP_DIALOG = 'SHOW_ITEM_GROUP_DIALOG';
export const COMPANY_ITEMS_FAILED = 'COMPANY_ITEMS_FAILED';
export const TOGGLE_ADD_ITEMS = 'TOGGLE_ADD_ITEMS';
export const SET_ITEM_GROUP = 'SET_ITEM_GROUP';
export const SET_ITEM_UNITS = 'SET_ITEM_UNITS';
export const SET_GROUPED_ITEM_UNITS = 'SET_GROUPED_ITEM_UNITS';
export const UPDATE_SELECTED_ITEM_GROUP = 'UPDATE_SELECTED_ITEM_GROUP';
export const UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG =
  'UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG';
export const UPDATE_ROUNDOFF_RANGE_VALUE = 'UPDATE_ROUNDOFF_RANGE_VALUE';
export const ADD_RANGE_TO_ITEM_GROUP = 'ADD_RANGE_TO_ITEM_GROUP';
export const UPDATE_RATE_CODE_VALUE = 'UPDATE_RATE_CODE_VALUE';
export const ADD_OLD_TAX_REGIME = 'ADD_OLD_TAX_REGIME';
export const DELETE_RANGE_FROM_ITEM_GROUP = 'DELETE_RANGE_FROM_ITEM_GROUP';
export const RESET_SELECTED_ITEM_GROUP = 'RESET_SELECTED_ITEM_GROUP;';
export const SET_ALL_ITEM_GROUP = 'SET_ALL_ITEM_GROUP';
export const SET_ITEM_PAYLOAD = 'SET_ITEM_PAYLOAD';
export const SET_ITEM_TAGS = 'SET_ITEM_TAGS';
export const SHOW_ITEM_SALES_PRICE = 'SHOW_ITEM_SALES_PRICE';
export const CANCEL_ITEM_SALES_PRICE = 'CANCEL_ITEM_SALES_PRICE';
export const RESET_ITEM_PAYLOAD = 'RESET_ITEM_PAYLOAD';
export const SET_ITEM_GROUP_SEARCH_TEXT = 'SET_ITEM_GROUP_SEARCH_TEXT';
export const SHOW_SALES_PRICE_VALUE = 'SHOW_SALES_PRICE_VALUE';
export const GET_BRANCHS = 'GET_BRANCHS';
export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_ITEM_FETCHED = 'SET_ITEM_FETCHED';
export const SET_ITEM_PROPERTY = 'SET_ITEM_PROPERTY';
export const SET_ITEM_PARTY_ID = 'SET_ITEM_PARTY_ID';
export const SET_ITEMS_BY_GROUP = 'SET_ITEMS_BY_GROUP';
export const SET_SELECTED_ITEM_GROUP = 'SET_SELECTED_ITEM_GROUP';
export const FETCH_ITEMS_MASTER_REQUESTED = 'FETCH_ITEMS_MASTER_REQUESTED';
export const FETCH_ITEMS_MASTER_SUCCESS = 'FETCH_ITEMS_MASTER_SUCCESS';
export const FETCH_ITEMS_MASTER_FAILED = 'FETCH_ITEMS_MASTER_FAILED';

export const FETCH_ITEMS_GROUPED_REQUESTED = 'FETCH_ITEMS_GROUPED_REQUESTED';
export const FETCH_ITEMS_GROUPED_SUCCESS = 'FETCH_ITEMS_GROUPED_SUCCESS';
export const FETCH_ITEMS_GROUPED_FAILED = 'FETCH_ITEMS_GROUPED_FAILED';

export const FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED = 'FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED';
export const FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS = 'FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS';
export const FETCH_ITEMS_GROUPED_VARIANTS_FAILED = 'FETCH_ITEMS_GROUPED_VARIANTS_FAILED';

export const TOGGLE_QTY_FILTER = 'TOGGLE_QTY_FILTER';

export const ITEM_VARIANT_SELECT_ALL = 'ITEM_VARIANT_SELECT_ALL';
// export const SET_ALL_ITEM_COLLECTIONS = 'SET_ALL_ITEM_COLLECTIONS';
// export const SET_ALL_ITEM_BASE_MATERIALS = 'SET_ALL_ITEM_BASE_MATERIALS';
// export const SET_ALL_ITEM_COLORS = 'SET_ALL_ITEM_COLORS';
export const SET_AVAILABLE_QTY = 'SET_AVAILABLE_QTY';
export const GET_AVAILABLE_QTY_REQUESTED = 'GET_AVAILABLE_QTY_REQUESTED';
export const GET_AVAILABLE_QTY_FAILED = 'GET_AVAILABLE_QTY_FAILED';
export const SET_TAB_VIEW_ITEM_GROUP = 'SET_TAB_VIEW_ITEM_GROUP';
export const SHOW_EDIT_ITEM_GROUP_DIALOG = 'SHOW_EDIT_ITEM_GROUP_DIALOG';
export const SAVE_ITEM_GROUP_REQUESTED = 'SAVE_ITEM_GROUP_REQUESTED';
export const SAVE_ITEM_GROUP_SUCCESS = 'SAVE_ITEM_GROUP_SUCCESS';
export const SAVE_ITEM_GROUP_FAILURE = 'SAVE_ITEM_GROUP_FAILURE';
export const SET_UPDATED_ITEM_GROUP = 'SET_UPDATED_ITEM_GROUP';
export const EDIT_ITEM_MODE = 'EDIT_ITEM_MODE';
export const RESET_VARIANT_ITEM = 'RESET_VARIANT_ITEM';
export const SET_UPDATED_ITEM = 'SET_UPDATED_ITEM';
export const EXPAND_ITEMS = 'EXPAND_ITEMS';
export const ADD_VOUCHER_PARTY_TO_ITEM = 'ADD_VOUCHER_PARTY_TO_ITEM';
export const TOGGLE_ITEM_ALERT_DIALOG = 'TOGGLE_ITEM_ALERT_DIALOG';
export const TOGGLE_PERSIST_MODE = 'TOGGLE_PERSIST_MODE';
export const TOGGLE_ITEM_VARIANT = 'TOGGLE_ITEM_VARIANT';
export const SET_PERSIST_PROPERTY = 'SET_PERSIST_PROPERTY';
export const RESET_PERSIST_PROPERTY = 'RESET_PERSIST_PROPERTY';
export const SET_IMAGE_LIST = 'SET_IMAGE_LIST';
export const SET_VOUCHER_UPLOADED_DOCUMENTS = 'SET_VOUCHER_UPLOADED_DOCUMENTS';
export const UPDATE_LAST_LINE_ITEM_PRICE = 'UPDATE_LAST_LINE_ITEM_PRICE';
export const RESET_TAB_VIEW_ITEM_GROUP = 'RESET_TAB_VIEW_ITEM_GROUP';
export const SET_WSP_ROUND_OFF_FLAG = 'SET_WSP_ROUND_OFF_FLAG';
export const SET_RSP_ROUND_OFF_FLAG = 'SET_RSP_ROUND_OFF_FLAG';
export const SET_SP_LOCK = 'SET_SP_LOCK';
export const FETCH_ALL_ITEM_GROUP_REQUESTED = 'FETCH_ALL_ITEM_GROUP_REQUESTED';
export const FETCH_ALL_ITEM_GROUP_SUCCESS = 'FETCH_ALL_ITEM_GROUP_SUCCESS';
export const FETCH_ALL_ITEM_GROUP_FAILED = 'FETCH_ALL_ITEM_GROUP_FAILED';
export const SET_ATTRIBUTE_FILTER = 'SET_ATTRIBUTE_FILTER';
export const SET_ITEM_TAGS_FILTER = 'SET_ITEM_TAGS_FILTER';
export const SET_ITEM_GROUPS_FILTER = 'SET_ITEM_GROUPS_FILTER';
export const RESET_ATTRIBUTE_FILTER = 'RESET_ATTRIBUTE_FILTER';
export const SET_ATTRIBUTE_FILTER_FROM_MENU = 'SET_ATTRIBUTE_FILTER_FROM_MENU';
export const CLEAR_UPDATE_ATTRIBUTE_FILTER = 'CLEAR_UPDATE_ATTRIBUTE_FILTER';
export const SET_ITEM_TYPE_FILTER = 'SET_ITEM_TYPE_FILTER';
export const ITEM_CREATION_REQUESTED = 'ITEM_CREATION_REQUESTED';
export const ITEM_CREATION_FAILED = 'ITEM_CREATION_FAILED';
export const ITEM_CREATION_SUCCESS = 'ITEM_CREATION_SUCCESS';
export const ITEM_UPDATE_REQUESTED = 'ITEM_UPDATE_REQUESTED';
export const ITEM_UPDATE_FAILED = 'ITEM_UPDATE_FAILED';
export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS';
export const HIDDEN_ITEM_LIST_REQUESTED = 'HIDDEN_ITEM_LIST_REQUESTED';
export const HIDDEN_ITEM_LIST_SUCCESS = 'HIDDEN_ITEM_LIST_SUCCESS';
export const HIDDEN_ITEM_LIST_FAILED = 'HIDDEN_ITEM_LIST_FAILED';
export const SET_ITEM_PRICE_FILTER_BY = 'SET_ITEM_PRICE_FILTER_BY';
export const SET_ITEM_PRICE_FILTER_VALUE = 'SET_ITEM_PRICE_FILTER_VALUE';
//item attributes related
export const ADD_ITEM_ATTRIBUTE_REQUESTED = 'ADD_ITEM_ATTRIBUTE_REQUESTED';
export const ADD_ITEM_ATTRIBUTE_SUCCESS = 'ADD_ITEM_ATTRIBUTE_SUCCESS';
export const ADD_ITEM_ATTRIBUTE_FAILED = 'ADD_ITEM_ATTRIBUTE_FAILED';

export const UPDATE_ITEM_ATTRIBUTE_REQUESTED = 'UPDATE_ITEM_ATTRIBUTE_REQUESTED';
export const UPDATE_ITEM_ATTRIBUTE_SUCCESS = 'UPDATE_ITEM_ATTRIBUTE_SUCCESS';
export const UPDATE_ITEM_ATTRIBUTE_FAILED = 'UPDATE_ITEM_ATTRIBUTE_FAILED';

export const DELETE_ITEM_ATTRIBUTE_REQUESTED = 'DELETE_ITEM_ATTRIBUTE_REQUESTED';
export const DELETE_ITEM_ATTRIBUTE_SUCCESS = 'DELETE_ITEM_ATTRIBUTE_SUCCESS';
export const DELETE_ITEM_ATTRIBUTE_FAILED = 'DELETE_ITEM_ATTRIBUTE_FAILED';

export const FETCH_ITEM_ATTRIBUTES_REQUESTED = 'FETCH_ITEM_ATTRIBUTES_REQUESTED';
export const FETCH_ITEM_ATTRIBUTES_SUCCESS = 'FETCH_ITEM_ATTRIBUTES_SUCCESS';
export const FETCH_ITEM_ATTRIBUTES_FAILED = 'FETCH_ITEM_ATTRIBUTES_FAILED';

export const UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED =
  'UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED';
export const UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED =
  'UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED';
export const UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS =
  'UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS';

export const FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED = 'FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED';
export const FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED =
  'FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED';
export const FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS =
  'FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS';

export const ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED = 'ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED';
export const ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS = 'ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS';
export const ADD_ITEM_ATTRIBUTE_VALUE_FAILED = 'ADD_ITEM_ATTRIBUTE_VALUE_FAILED';

export const UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED = 'UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED';
export const UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS = 'UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS';
export const UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED = 'UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED';

export const SET_ITEM_ATTRIBUTE_VALUE_KEYED = 'SET_ITEM_ATTRIBUTE_VALUE_KEYED';

export const FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED = 'FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED';
export const FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS = 'FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS';
export const FETCH_ITEM_ATTRIBUTE_VALUE_FAILED = 'FETCH_ITEM_ATTRIBUTE_VALUE_FAILED';

export const FETCH_ITEM_GROUP_REQUESTED = 'FETCH_ITEM_GROUP_REQUESTED';
export const FETCH_ITEM_GROUP_SUCCESS = 'FETCH_ITEM_GROUP_SUCCESS';
export const FETCH_ITEM_GROUP_FAILED = 'FETCH_ITEM_GROUP_FAILED';

export const SET_ATTRIBUTE_VALUE = 'SET_ATTRIBUTE_VALUE';
export const SET_NAME_AND_CODE = 'SET_NAME_AND_CODE';
export const SET_ITEM_ATTRIBUTE_CONFIG = 'SET_ITEM_ATTRIBUTE_CONFIG';
export const RESET_ITEM_GROUP_PAYLOAD = 'RESET_ITEM_GROUP_PAYLOAD';
export const FETCH_ITEM_GROUPS_BY_HSN_REQUESTED = 'FETCH_ITEM_GROUPS_BY_HSN_REQUESTED';
export const FETCH_ITEM_GROUPS_BY_HSN_SUCCESS = 'FETCH_ITEM_GROUPS_BY_HSN_SUCCESS';
export const FETCH_ITEM_GROUPS_BY_HSN_FAILED = 'FETCH_ITEM_GROUPS_BY_HSN_FAILED';

export const FETCH_CATALOGUE_ITEM_GROUPS_FAILED = 'FETCH_CATALOGUE_ITEM_GROUPS_FAILED';
export const FETCH_CATALOGUE_ITEM_GROUPS_SUCCESS = 'FETCH_CATALOGUE_ITEM_GROUPS_SUCCESS';
export const FETCH_CATALOGUE_ITEM_GROUPS_REQUESTED = 'FETCH_CATALOGUE_ITEM_GROUPS_REQUESTED';

export const FETCH_CATALOGUE_TAGS_REQUESTED = 'FETCH_CATALOGUE_TAGS_REQUESTED';
export const FETCH_CATALOGUE_TAGS_SUCCESS = 'FETCH_CATALOGUE_TAGS_SUCCESS';
export const FETCH_CATALOGUE_TAGS_FAILED = 'FETCH_CATALOGUE_TAGS_FAILED';

//item tags related
export const ADD_ITEM_TAG_REQUESTED = 'ADD_ITEM_TAG_REQUESTED';
export const ADD_ITEM_TAG_SUCCESS = 'ADD_ITEM_TAG_SUCCESS';
export const ADD_ITEM_TAG_FAILED = 'ADD_ITEM_TAG_FAILED';

export const UPDATE_ITEM_TAG_REQUESTED = 'UPDATE_ITEM_TAG_REQUESTED';
export const UPDATE_ITEM_TAG_SUCCESS = 'UPDATE_ITEM_TAG_SUCCESS';
export const UPDATE_ITEM_TAG_FAILED = 'UPDATE_ITEM_TAG_FAILED';

export const DELETE_ITEM_TAG_REQUESTED = 'DELETE_ITEM_TAG_REQUESTED';
export const DELETE_ITEM_TAG_SUCCESS = 'DELETE_ITEM_TAG_SUCCESS';
export const DELETE_ITEM_TAG_FAILED = 'DELETE_ITEM_TAG_FAILED';

export const FETCH_ITEM_TAGS_REQUESTED = 'FETCH_ITEM_TAGS_REQUESTED';
export const FETCH_ITEM_TAGS_SUCCESS = 'FETCH_ITEM_TAGS_SUCCESS';
export const FETCH_ITEM_TAGS_FAILED = 'FETCH_ITEM_TAGS_FAILED';

export const ADD_TAGS_TO_ITEMS_REQUESTED = 'ADD_TAGS_TO_ITEMS_REQUESTED';
export const ADD_TAGS_TO_ITEMS_SUCCESS = 'ADD_TAGS_TO_ITEMS_SUCCESS';
export const ADD_TAGS_TO_ITEMS_FAILED = 'ADD_TAGS_TO_ITEMS_FAILED';

export const REMOVE_TAGS_FROM_ITEMS_REQUESTED = 'REMOVE_TAGS_FROM_ITEMS_REQUESTED';
export const REMOVE_TAGS_FROM_ITEMS_SUCCESS = 'REMOVE_TAGS_FROM_ITEMS_SUCCESS';
export const REMOVE_TAGS_FROM_ITEMS_FAILED = 'REMOVE_TAGS_FROM_ITEMS_FAILED';

export const UPDATE_CATALOGUE_ITEM_POSITION_REQUESTED = 'UPDATE_CATALOGUE_ITEM_POSITION_REQUESTED';
export const UPDATE_CATALOGUE_ITEM_POSITION_SUCCESS = 'UPDATE_CATALOGUE_ITEM_POSITION_SUCCESS';
export const UPDATE_CATALOGUE_ITEM_POSITION_FAILED = 'UPDATE_CATALOGUE_ITEM_POSITION_FAILED';

export const ADD_ITEM_TAG_IN_SELECTED = 'ADD_ITEM_TAG_IN_SELECTED';
export const UPDATE_ITEM_TAG_IN_SELECTED = 'UPDATE_ITEM_TAG_IN_SELECTED';
export const DELETE_ITEM_TAG_IN_SELECTED = 'DELETE_ITEM_TAG_IN_SELECTED';

//item tags related
export const ADD_PARTY_TAG_REQUESTED = 'ADD_PARTY_TAG_REQUESTED';
export const ADD_PARTY_TAG_SUCCESS = 'ADD_PARTY_TAG_SUCCESS';
export const ADD_PARTY_TAG_FAILED = 'ADD_PARTY_TAG_FAILED';

export const UPDATE_PARTY_TAG_REQUESTED = 'UPDATE_PARTY_TAG_REQUESTED';
export const UPDATE_PARTY_TAG_SUCCESS = 'UPDATE_PARTY_TAG_SUCCESS';
export const UPDATE_PARTY_TAG_FAILED = 'UPDATE_PARTY_TAG_FAILED';

export const DELETE_PARTY_TAG_REQUESTED = 'DELETE_PARTY_TAG_REQUESTED';
export const DELETE_PARTY_TAG_SUCCESS = 'DELETE_PARTY_TAG_SUCCESS';
export const DELETE_PARTY_TAG_FAILED = 'DELETE_PARTY_TAG_FAILED';

export const FETCH_PARTY_TAGS_REQUESTED = 'FETCH_PARTY_TAGS_REQUESTED';
export const FETCH_PARTY_TAGS_SUCCESS = 'FETCH_PARTY_TAGS_SUCCESS';
export const FETCH_PARTY_TAGS_FAILED = 'FETCH_PARTY_TAGS_FAILED';

export const ADD_TAGS_TO_PARTIES_REQUESTED = 'ADD_TAGS_TO_PARTIES_REQUESTED';
export const ADD_TAGS_TO_PARTIES_SUCCESS = 'ADD_TAGS_TO_PARTIES_SUCCESS';
export const ADD_TAGS_TO_PARTIES_FAILED = 'ADD_TAGS_TO_PARTIES_FAILED';

export const REMOVE_TAGS_FROM_PARTY_REQUESTED = 'REMOVE_TAGS_FROM_PARTY_REQUESTED';
export const REMOVE_TAGS_FROM_PARTY_SUCCESS = 'REMOVE_TAGS_FROM_PARTY_SUCCESS';
export const REMOVE_TAGS_FROM_PARTY_FAILED = 'REMOVE_TAGS_FROM_PARTY_FAILED';

export const HANDLE_CHANGE_PARTY_TAGS = 'HANDLE_CHANGE_PARTY_TAGS';
export const ADD_PARTY_TAG_IN_SELECTED = 'ADD_PARTY_TAG_IN_SELECTED';
export const UPDATE_PARTY_TAG_IN_SELECTED = 'UPDATE_PARTY_TAG_IN_SELECTED';
export const DELETE_PARTY_TAG_IN_SELECTED = 'DELETE_PARTY_TAG_IN_SELECTED';

// Vouchers Specific

export const FETCH_UNPAID_VOUCHERS_REQUESTED = 'FETCH_UNPAID_VOUCHERS_REQUESTED';
export const FETCH_UNPAID_VOUCHERS_SUCCESS = 'FETCH_UNPAID_VOUCHERS_SUCCESS';
export const FETCH_UNPAID_VOUCHERS_FAILURE = 'FETCH_UNPAID_VOUCHERS_FAILURE';
export const CLEAR_UNPAID_VOUCHERS = 'CLEAR_UNPAID_VOUCHERS';
export const VOUCHER_DATE_RANGE = 'VOUCHER_DATE_RANGE';
export const VOUCHER_DATE = 'VOUCHER_DATE';
export const VOUCHERS_LIST_REQUESTED = 'VOUCHERS_LIST_REQUESTED';
export const VOUCHERS_LIST_SUCCESS = 'VOUCHERS_LIST_SUCCESS';
export const VOUCHER_LIST_COUNT_REQUESTED = 'VOUCHER_LIST_COUNT_REQUESTED';
export const VOUCHER_LIST_COUNT_SUCCESS = 'VOUCHER_LIST_COUNT_SUCCESS';
export const VOUCHER_LIST_COUNT_FAILED = 'VOUCHER_LIST_COUNT_FAILED';
export const SAVED_VOUCHER_SUCCESS = 'SAVED_VOUCHER_SUCCESS';
export const UPDATED_VOUCHER_SUCCESS = 'UPDATED_VOUCHER_SUCCESS';
export const VOUCHERS_LIST_FAILED = 'VOUCHERS_LIST_FAILED';
export const SET_VOUCHER_TYPE = 'SET_VOUCHER_TYPE';
export const VOUCHER_BRANCH_POPULATE = 'VOUCHER_BRANCH_POPULATE';
export const VOUCHERS_DETAILS_ACCOUNTS_POPULATE = 'VOUCHERS_DETAILS_ACCOUNTS_POPULATE';
export const SET_SUPPLIER_INVOICE_NO = 'SET_SUPPLIER_INVOICE_NO';
export const SET_SUPPLIER_INVOICE_DATE = 'SET_SUPPLIER_INVOICE_DATE';
export const SET_DUE_DATE = 'SET_DUE_DATE';
export const SET_ESTIMATION_LINKED_ITEM_LIST = 'SET_ESTIMATION_LINKED_ITEM_LIST';

export const GET_VOUCHER_REQUESTED = 'GET_VOUCHER_REQUESTED';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_FAILED = 'GET_VOUCHER_FAILED';

export const INCREMENT_VOUCHER_REQUESTED = 'INCREMENT_VOUCHER_REQUESTED';
export const INCREMENT_VOUCHER_SUCCESS = 'INCREMENT_VOUCHER_SUCCESS';
export const INCREMENT_VOUCHER_FAILED = 'INCREMENT_VOUCHER_FAILED';

export const GENERATE_VOUCHER_PDF_REQUESTED = 'GENERATE_VOUCHER_PDF_REQUESTED';
export const GENERATE_VOUCHER_PDF_SUCCESS = 'GENERATE_VOUCHER_PDF_SUCCESS';
export const GENERATE_VOUCHER_PDF_FAILED = 'GENERATE_VOUCHER_PDF_FAILED';
export const RESET_VOUCHER_FORM = 'RESET_VOUCHER_FORM';
export const CLOSE_AND_RESET_VOUCHER_FORM_DONE = 'CLOSE_AND_RESET_VOUCHER_FORM_DONE';
export const TOGGLE_VOUCHER_DETAILS_LINK = 'TOGGLE_VOUCHER_DETAILS_LINK';
export const TOGGLE_ALERT_DIALOG = 'TOGGLE_ALERT_DIALOG';
export const UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER = 'UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER';
export const POPULATE_OTHER_CHARGES_AFTER_TAX = 'POPULATE_OTHER_CHARGES_AFTER_TAX';
export const POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL = 'POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL';
export const POPULATE_LINE_AMOUNT_SUM = 'POPULATE_LINE_AMOUNT_SUM';
export const POPULATE_BILL_FINAL_AMOUNT = 'POPULATE_BILL_FINAL_AMOUNT';
export const POPULATE_BILL_TAX_AMOUNT = 'POPULATE_BILL_TAX_AMOUNT';
export const POPULATE_BILL_ITEMS_PRICE = 'POPULATE_BILL_ITEMS_PRICE';
export const POPULATE_BILL_TAX = 'POPULATE_BILL_TAX';
export const POPULATE_BILL_DISCOUNT_AMOUNT = 'POPULATE_BILL_DISCOUNT_AMOUNT';
export const POPULATE_ADDITIONAL_DISCOUNT_AMOUNT = 'POPULATE_ADDITIONAL_DISCOUNT_AMOUNT';
export const POPULATE_DUE_AMOUNT = 'POPULATE_DUE_AMOUNT';
export const POPULATE_ROUNDOFF_VALUE = 'POPULATE_ROUNDOFF_VALUE';
export const SET_LINE_ITEM_SEARCH_TEXT = 'SET_LINE_ITEM_SEARCH_TEXT';
export const ON_EDIT_ATTRIBUTE_VALUE = 'ON_EDIT_ATTRIBUTE_VALUE';
export const TOGGLE_ATTRIBUTE_VALUE_FORM = 'TOGGLE_ATTRIBUTE_VALUE_FORM';
export const SET_SELECTED_CATALOGUE = 'SET_SELECTED_CATALOGUE';

export const RECONCILE_VOUCHER_SUCCESS = 'RECONCILE_VOUCHER_SUCCESS';
export const RECONCILE_VOUCHER_FAILED = 'RECONCILE_VOUCHER_FAILED';
export const TOGGLE_TRANSPORT_LINK = 'TOGGLE_TRANSPORT_LINK';
export const TOGGLE_SALES_PERSON_LINK = 'TOGGLE_SALES_PERSON_LINK';

export const FETCH_SHARE_VOUCHER_DATA_REQUESTED = 'FETCH_SHARE_VOUCHER_DATA_REQUESTED';
export const FETCH_SHARE_VOUCHER_DATA_SUCCESS = 'FETCH_SHARE_VOUCHER_DATA_SUCCESS';
export const FETCH_SHARE_VOUCHER_DATA_FAILED = 'FETCH_SHARE_VOUCHER_DATA_FAILED';

export const FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED =
  'FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED';
export const FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS = 'FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS';
export const FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED = 'FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED';

export const SHARE_VOUCHER_REQUESTED = 'SHARE_VOUCHER_REQUESTED';
export const SHARE_VOUCHER_SUCCESS = 'SHARE_VOUCHER_SUCCESS';
export const SHARE_VOUCHER_FAILED = 'SHARE_VOUCHER_FAILED';

export const FETCH_VOUCHER_ITEMS_REQUESTED = 'FETCH_VOUCHER_ITEMS_REQUESTED';
export const FETCH_VOUCHER_ITEMS_SUCCESS = 'FETCH_VOUCHER_ITEMS_SUCCESS';
export const SET_DUPLICATE_ITEM_BEHAVIOUR = 'SET_DUPLICATE_ITEM_BEHAVIOUR';
export const TOGGLE_LINE_ITEM_BARCODE_SEARCH = 'TOGGLE_LINE_ITEM_BARCODE_SEARCH';
export const TOGGLE_ITEM_LAST_SP_UNIT_PRICE = 'TOGGLE_ITEM_LAST_SP_UNIT_PRICE';
export const UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY =
  'UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY';
export const FETCH_VOUCHER_ITEMS_FAILED = 'FETCH_VOUCHER_ITEMS_FAILED';
export const VOUCHER_LIST_LOADING = 'VOUCHER_LIST_LOADING';
export const ADD_EXPENSE_LINE_ITEM = 'ADD_EXPENSE_LINE_ITEM';
export const TABLE_LOADING = 'TABLE_LOADING';
// Contact Specific

export const ADD_CONTACT_DATA_REQUEST = 'ADD_CONTACT_DATA_REQUEST';
export const TOGGLE_OPEN_ADD_CONTACT = 'TOGGLE_OPEN_ADD_CONTACT';
export const ADD_CONTACT_DATA_SUCCESSS = 'ADD_CONTACT_DATA_SUCCESSS';
export const ADD_CONTACT_DATA_FAILED = 'ADD_CONTACT_DATA_FAILED';
export const CONTACT_LIST_FAILED = 'CONTACT_LIST_FAILED';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const PARTY_SEARCH_SUCCESS = 'PARTY_SEARCH_SUCCESS';
export const PARTY_SEARCH_REQUESTED = 'PARTY_SEARCH_REQUESTED';
export const PARTY_SEARCH_FAILED = 'PARTY_SEARCH_FAILED';
export const PARTY_LIST_ITEM_UPDATE = 'PARTY_LIST_ITEM_UPDATE';
export const ACCOUNT_SEARCH_SUCCESS = 'ACCOUNT_SEARCH_SUCCESS';
export const ACCOUNT_SEARCH_REQUESTED = 'ACCOUNT_SEARCH_REQUESTED';
export const ACCOUNT_SEARCH_FAILED = 'ACCOUNT_SEARCH_FAILED';
export const CHECK_FOR_NAME_FAILED = 'CHECK_FOR_NAME_FAILED';
export const COMPANY_BRANCH_FAILURE = 'COMPANY_BRANCH_FAILURE';
export const COMPANY_BRANCH_SUCCESS = 'COMPANY_BRANCH_SUCCESS';
export const COMPANY_BRANCH_UPDATE = 'COMPANY_BRANCH_UPDATE';
export const CONTACT_LIST_REQUESTED = 'CONTACT_LIST_REQUESTED';
export const CHECK_FOR_NAME_SUCCESS = 'CHECK_FOR_NAME_SUCCESS';
export const ADD_CONTACT_NAME_FAILED = 'ADD_CONTACT_NAME_FAILED';
export const ADD_CONTACT_NAME_REQUEST = 'ADD_CONTACT_NAME_REQUEST';
export const ADD_CONTACT_NAME_SUCCESS = 'ADD_CONTACT_NAME_SUCCESS';
export const UPDATE_CONTACT_NAME_FAILED = 'UPDATE_CONTACT_NAME_FAILED';
export const UPDATE_CONTACT_NAME_SUCCESS = 'UPDATE_CONTACT_NAME_SUCCESS';
export const STATEMENT_SUCCESS = 'STATEMENT_SUCCESS';
export const STATEMENT_FAILED = 'STATEMENT_FAILED';
export const STATEMENT_LOADING = 'STATEMENT_LOADING';
export const STATEMENT_PAGINATE_SUCCESS = 'STATEMENT_PAGINATE_SUCCESS';
export const STATEMENT_PAGINATE_FAILED = 'STATEMENT_ PAGINATE_FAILED';
export const STATEMENT_PAGINATE_LOADING = 'STATEMENT_PAGINATE_LOADING';
export const STATEMENT_DATE_SET = 'STATEMENT_DATE_SET';
export const SHARE_STATEMENT_SUCCESS = 'SHARE_STATEMENT_SUCCESS';
export const SHARE_PARTY_STATEMENT_FAILURE = 'SHARE_PARTY_STATEMENT_FAILURE';
export const UPDATE_PARTY_REQUESTED = 'UPDATE_PARTY_REQUESTED';
export const UPDATE_PARTY_SUCCESS = 'UPDATE_PARTY_SUCCESS';
export const UPDATE_PARTY_FAILED = 'UPDATE_PARTY_FAILED';
export const GET_PARTY_PROFILE_REQUESTED = 'GET_PARTY_PROFILE_REQUESTED';
export const GET_PARTY_PROFILE_SUCCESS = 'GET_PARTY_PROFILE_SUCCESS';
export const GET_PARTY_PROFILE_FAILED = 'GET_PARTY_PROFILE_FAILED';
export const FETCH_VOUCHERS_SUMMARY_SUCCESS = 'FETCH_VOUCHERS_SUMMARY_SUCCESS';
export const FETCH_VOUCHERS_SUMMARY_FAILED = 'FETCH_VOUCHERS_SUMMARY_FAILED';
export const FETCH_PARTY_RELATION_TYPES_INITIATED = 'FETCH_PARTY_RELATION_TYPES_INITIATED';
export const FETCH_PARTY_RELATION_TYPES_SUCCESS = 'FETCH_PARTY_RELATION_TYPES_SUCCESS';
export const FETCH_PARTY_RELATION_TYPES_FAILED = 'FETCH_PARTY_RELATION_TYPES_FAILED';
export const TOGGLE_PARTY_PROFILE_MODAL = 'TOGGLE_PARTY_PROFILE_MODAL';
export const FETCH_PARTY_CARD_FAILED = 'FETCH_PARTY_CARD_FAILED';
export const FETCH_PARTY_CARD_SUCCESS = 'FETCH_PARTY_CARD_SUCCESS';
export const SET_FILTER_DATES = 'SET_FILTER_DATES';
export const SET_RECONCILE_DATE = 'SET_RECONCILE_DATE';

export const FETCH_AJ_TALLY_PARTIES_REQUEST = 'FETCH_AJ_TALLY_PARTIES_REQUEST';
export const FETCH_AJ_TALLY_PARTIES_SUCCESS = 'FETCH_AJ_TALLY_PARTIES_SUCCESS';
export const FETCH_AJ_TALLY_PARTIES_ERROR = 'FETCH_AJ_TALLY_PARTIES_ERROR';

export const SEARCH_AJ_TALLY_PARTIES_REQUEST = 'SEARCH_AJ_TALLY_PARTIES_REQUEST';
export const SEARCH_AJ_TALLY_PARTIES_SUCCESS = 'SEARCH_AJ_TALLY_PARTIES_SUCCESS';
export const SEARCH_AJ_TALLY_PARTIES_ERROR = 'SEARCH_AJ_TALLY_PARTIES_ERROR';

export const FETCH_ACCOUNT_CARD_FAILED = 'FETCH_ACCOUNT_CARD_FAILED';
export const FETCH_ACCOUNT_CARD_SUCCESS = 'FETCH_ACCOUNT_CARD_SUCCESS';

export const UPDATE_SALES_DISCOUNT_REQUEST = 'UPDATE_SALES_DISCOUNT_REQUEST';
export const UPDATE_SALES_DISCOUNT_SUCCESS = 'UPDATE_SALES_DISCOUNT_SUCCESS';
export const UPDATE_SALES_DISCOUNT_ERROR = 'UPDATE_SALES_DISCOUNT_ERROR';
export const UPDATE_NAME_IN_DRAWER = 'UPDATE_NAME_IN_DRAWER';

export const UPDATE_SHIPPING_ADDRESS_REQUEST = 'UPDATE_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_ERROR = 'UPDATE_SHIPPING_ADDRESS_ERROR';

export const UPDATE_BANK_DETAILS_REQUEST = 'UPDATE_BANK_DETAILS_REQUEST';
export const UPDATE_BANK_DETAILS_SUCCESS = 'UPDATE_BANK_DETAILS_SUCCESS';
export const UPDATE_BANK_DETAILS_ERROR = 'UPDATE_BANK_DETAILS_ERROR';

export const FETCH_CONTACTS_REQUESTED = 'FETCH_CONTACTS_REQUESTED';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILED = 'FETCH_CONTACTS_FAILED';

export const REQUEST_PRIMARY_CONTACT_REQUESTED = 'REQUEST_PRIMARY_CONTACT_REQUESTED';
export const REQUEST_PRIMARY_CONTACT_SUCCESS = 'REQUEST_PRIMARY_CONTACT_SUCCESS';
export const REQUEST_PRIMARY_CONTACT_FAILED = 'REQUEST_PRIMARY_CONTACT_FAILED';

export const ADD_SECONDARY_CONTACT_REQUESTED = 'C_CONTACT_REQUESTED';
export const ADD_SECONDARY_CONTACT_SUCCESS = 'ADD_SECONDARY_CONTACT_SUCCESS';
export const ADD_SECONDARY_CONTACT_FAILED = 'ADD_SECONDARY_CONTACT_FAILED';

export const FETCH_CONTACTS_SEARCH_REQUESTED = 'FETCH_CONTACTS_SEARCH_REQUESTED';
export const FETCH_CONTACTS_SEARCH_SUCCESS = 'FETCH_CONTACTS_SEARCH_SUCCESS';
export const FETCH_CONTACTS_SEARCH_FAILED = 'FETCH_CONTACTS_SEARCH_FAILED';

export const FETCH_PARTY_SALES_DISCOUNT_REQUESTED = 'FETCH_PARTY_SALES_DISCOUNT_REQUESTED';
export const FETCH_PARTY_SALES_DISCOUNT_SUCCESS = 'FETCH_PARTY_SALES_DISCOUNT_SUCCESS';
export const FETCH_PARTY_SALES_DISCOUNT_FAILED = 'FETCH_PARTY_SALES_DISCOUNT_FAILED';

export const FETCH_PARTY_PURCHASE_DISCOUNT_REQUESTED = 'FETCH_PARTY_PURCHASE_DISCOUNT_REQUESTED';
export const FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS = 'FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS';
export const FETCH_PARTY_PURCHASE_DISCOUNT_FAILED = 'FETCH_PARTY_PURCHASE_DISCOUNT_FAILED';

export const SET_PARTY_SALES_DISCOUNT = 'SET_PARTY_SALES_DISCOUNT';
export const SET_PARTY_PURCHASE_DISCOUNT = 'SET_PARTY_PURCHASE_DISCOUNT';
export const SET_STATEMENT_BRANCH_FILTER = 'SET_STATEMENT_BRANCH_FILTER';

export const FETCH_TRANSPORTER_PARTY_SUCCESS = 'FETCH_TRANSPORTER_PARTY_SUCCESS';
export const FETCH_TRANSPORTER_PARTY_FAILED = 'FETCH_TRANSPORTER_PARTY_FAILED';

export const FETCH_ACCOUNT_ID_SUCCESS = 'FETCH_ACCOUNT_ID_SUCCESS';
export const FETCH_PARTY_ACCOUNT_ID_FAILED = 'FETCH_PARTY_ACCOUNT_ID_FAILED';

export const ADD_PARTY_PICKER_FIELD_DATA = 'ADD_PARTY_PICKER_FIELD_DATA';
export const CURRENT_VOUCHER_LIST_SUCCESS = 'CURRENT_VOUCHER_LIST_SUCCESS';

export const CHANGE_STATEMENT_DATE = 'CHANGE_STATEMENT_DATE';
export const ADD_RP_ACCOUNT = 'ADD_RP_ACCOUNT';
// Purchase Vouchers Specific
export const POPULATE_COUPON_DISCOUNT = 'POPULATE_COUPON_DISCOUNT';
export const UPDATE_BUSINESS_CONTACT = 'UPDATE_BUSINESS_CONTACT';
export const POPULATE_ADDTIONAL_DISCOUNT = 'POPULATE_ADDTIONAL_DISCOUNT';
export const POPULATE_OTHER_CHARGES = 'POPULATE_OTHER_CHARGES';
export const POPULATE_OTHER_CHARGES_TOTAL = 'POPULATE_OTHER_CHARGES_TOTAL';
export const POPULATE_ITEM_QTYS_TOTAL = 'POPULATE_ITEM_QTYS_TOTAL';
export const TOGGLE_SHIPPING_DETAILS_LINK = 'TOGGLE_SHIPPING_DETAILS_LINK';
export const TOGGLE_PURCHASE_SALES_DRAWER = 'TOGGLE_PURCHASE_SALES_DRAWER';
export const TOGGLE_ADD_EDIT_DRAWER = 'TOGGLE_ADD_EDIT_DRAWER';
export const TOGGLE_GROUPWISE_DISCOUNT_MODAL = 'TOGGLE_GROUPWISE_DISCOUNT_MODAL';
export const TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL = 'TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL';
export const UPDATE_SHIPPING_FOR_SELECTED_VOUCHER = 'UPDATE_SHIPPING_FOR_SELECTED_VOUCHER';
export const SET_DRAWER_MODE = 'SET_DRAWER_MODE';

export const RESET_VOUCHER_DETAILS = 'RESET_VOUCHER_DETAILS';
export const RESET_TRANSPORT_DETAILS = 'RESET_TRANSPORT_DETAILS';
export const RESET_LINE_ITEMS_DETAILS = 'RESET_LINE_ITEMS_DETAILS';
export const RESET_ADDTIONAL_DISCOUNT = 'RESET_ADDTIONAL_DISCOUNT';
export const UPDATE_VOUCHER_NO = 'UPDATE_VOUCHER_NO';
export const UPDATE_DATE = 'UPDATE_DATE';
export const UPDATE_VOUCHERS_DETAILS_ACCOUNTS = 'UPDATE_VOUCHERS_DETAILS_ACCOUNTS';

export const FETCH_ITEM_DETAILS_REQUESTED = 'FETCH_ITEM_DETAILS_REQUESTED';
export const FETCH_ITEM_DETAILS_SUCCESS = 'FETCH_ITEM_DETAILS_SUCCESS';
export const FETCH_ITEM_DETAILS_FAILED = 'FETCH_ITEM_DETAILS_FAILED';
export const SET_SHOW_ALL_ITEMS = 'SET_SHOW_ALL_ITEMS';

export const UPDATE_CONTACT_REQUESTED = 'UPDATE_CONTACT_REQUESTED';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILED = 'UPDATE_CONTACT_FAILED';

export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_ADDRESS_NAME = 'UPDATE_SHIPPING_ADDRESS_NAME';
export const ON_HIDE_SHOW_LINK = 'ON_HIDE_SHOW_LINK';
export const FETCH_VOUCHERS_DATA = 'FETCH_VOUCHERS_DATA';
export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHER_REQUEST';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILED = 'DELETE_VOUCHER_FAILED';
export const FETCH_VOUCHERS_DATA_REQUEST = 'FETCH_VOUCHERS_DATA_REQUEST';
export const FETCH_VOUCHERS_DATA_SUCCESS = 'FETCH_VOUCHERS_DATA_SUCCESS';
export const FETCH_VOUCHERS_DATA_FAILED = 'FETCH_VOUCHERS_DATA_FAILED';
export const SET_SELECTED_VOUCHER_ID = 'SET_SELECTED_VOUCHER_ID';
export const SET_SELECTED_VOUCHER_NO = 'SET_SELECTED_VOUCHER_NO';
export const UPDATE_PIN_CODE = 'UPDATE_PIN_CODE';
export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_SHIPPING_GEO_LOC = 'UPDATE_SHIPPING_GEO_LOC';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS';
export const FETCH_PAYMENT_METHOD_FAILED = 'FETCH_PAYMENT_METHOD_FAILED';
export const FETCH_BANK_LIST_SUCCESS = 'FETCH_BANK_LIST_SUCCESS';
export const FETCH_BANK_LIST_FAILED = 'FETCH_BANK_LIST_FAILED';
export const LINE_ITEM_INDEX = 'LINE_ITEM_INDEX';
export const SHOW_LINE_ITEM_PICKER = 'SHOW_LINE_ITEM_PICKER';
export const SET_CURRENT_ITEM_TO_FOCUS = 'SET_CURRENT_ITEM_TO_FOCUS';
export const UPDATE_LINE_ITEM_NAME = 'UPDATE_LINE_ITEM_NAME';
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM';
export const VIEW_TAX_ANALYSIS = 'VIEW_TAX_ANALYSIS';
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const UPDATE_LINE_ITEM_SR_NOS = 'UPDATE_LINE_ITEM_SR_NOS';
export const ADD_EXPENSE_LINE = 'ADD_EXPENSE_LINE';
export const UPDATE_LINE_ITEM_FIELD = 'UPDATE_LINE_ITEM_FIELD';
export const STORE_OLD_LINE_ITEM = 'STORE_OLD_LINE_ITEM';
export const RESET_OLD_LINE_ITEM = 'RESET_OLD_LINE_ITEM';
export const REFRESH_LINE_ITEM = 'REFRESH_LINE_ITEM';
export const DISCOUNT_CHANGE = 'DISCOUNT_CHANGE';
export const DELETE_LINE_ITEM = 'DELETE_LINE_ITEM';
export const HANDLE_ITEM_CALCULATIONS = 'HANDLE_ITEM_CALCULATIONS';
export const UPDATE_TAX_BRACKUP = 'UPDATE_TAX_BRACKUP';
export const FETCH_ALL_BRANCHES_SUCCESS = 'FETCH_ALL_BRANCHES_SUCCESS';
export const FETCH_ALL_BRANCHES_REQUESTED = 'FETCH_ALL_BRANCHES_REQUESTED';
export const FETCH_ALL_BRANCHES_FAILED = 'FETCH_ALL_BRANCHES_FAILED';
export const FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS = 'FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS';
export const FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED = 'FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED';
export const FETCH_ALL_AJ_TALLY_BRANCHES_FAILED = 'FETCH_ALL_AJ_TALLY_BRANCHES_FAILED';
export const ON_CLOSE_VOUCHER_PICKER = 'ON_CLOSE_VOUCHER_PICKER';
export const SET_SELECTED_VOUCHER_BY_REF_NO = 'SET_SELECTED_VOUCHER_BY_REF_NO';

export const UPDATE_GROUP_WISE_ITEM_COUNT = 'UPDATE_GROUP_WISE_ITEM_COUNT';
export const UPDATE_GROUP_TO_CODE_MAP = 'UPDATE_GROUP_TO_CODE_MAP';

export const UPDATE_NARRATION = 'UPDATE_NARRATION';
export const CLOSE_BUSINESS_CARD = 'CLOSE_BUSINESS_CARD';

export const ADD_LINE = 'ADD_LINE';
export const UPDATE_ACCOUNT_FIELD = 'UPDATE_ACCOUNT_FIELD';
export const UPDATE_FILTERED_NAMES = 'UPDATE_FILTERED_NAMES';
export const UPDATE_CHARGES = 'UPDATE_CHARGES';
export const DELETE_CHRAGES = 'DELETE_CHRAGES';
export const DELETE_OTHER_CHRAGES_AFTER_TAX = 'DELETE_OTHER_CHRAGES_AFTER_TAX';

export const HIDE_SHIPPING_DETAILS = 'HIDE_SHIPPING_DETAILS';
export const SET_DISCOUNT_UNITS = 'SET_DISCOUNT_UNITS';
export const SET_DISCOUNT_VALUE = 'SET_DISCOUNT_VALUE';
export const RESET_ADD_DISCOUNT = 'RESET_ADD_DISCOUNT';
export const TOGGLE_AUTO_REPEAT_LAST_ITEM = 'TOGGLE_AUTO_REPEAT_LAST_ITEM';
export const TOGGLE_AUTO_ADD_QTY = 'TOGGLE_AUTO_ADD_QTY';

export const DRAWER_OPEN_ADD_ACCOUNTS = 'DRAWER_OPEN_ADD_ACCOUNTS';
export const ON_FOCUS_CURRENT_INDEX = 'ON_FOCUS_CURRENT_INDEX';

export const SET_ROUND_OFF_VALUE = 'SET_ROUND_OFF_VALUE';
export const SET_ROUND_OFF_OFFSET = 'SET_ROUND_OFF_OFFSET';

export const TOGGLE_OTHER_CHARGES = 'ADD_NEW_CHARGES';
export const CURRENT_CHARGE_INDEX_FOCUS = 'CURRENT_CHARGE_INDEX_FOCUS';
export const CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS =
  'CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_SELECTED_VOUCHER = 'UPDATE_SELECTED_VOUCHER';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_REQUESTED = 'FETCH_ACCOUNTS_REQUESTED';
export const FETCH_ACCOUNTS_FAILED = 'FETCH_FAILED';
export const POPULATE_ACCOUNTS_SUGGESTION = 'POPULATE_ACCOUNTS_SUGGESTION';
export const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
export const DELETE_CHARGES = 'DELETE_CHARGES';
export const UPDATE_TRANSPORT_DETAILS = 'UPDATE_TRANSPORT_DETAILS';
export const UPDATE_SALES_PERSON_DETAILS = 'UPDATE_SALES_PERSON_DETAILS';

export const ON_HIDE_OTHER_CHARGES = 'ON_HIDE_OTHER_CHARGES';
export const ON_TOGGLE_EXCLUSIVE_DISCOUNT = 'ON_TOGGLE_EXCLUSIVE_DISCOUNT';
export const RESET_EXCLUSIVE_DISCOUNT = 'RESET_EXCLUSIVE_DISCOUNT';
export const ADD_OTHER_CHARGES_NEW_LINE = 'ADD_OTHER_CHARGES_NEW_LINE';
export const ON_HIDE_OTHER_CHARGES_AFTER_TAX = 'ON_HIDE_OTHER_CHARGES_AFTER_TAX';
export const RESET_VOUCHER_LINE_ITEMS = 'RESET_VOUCHER_LINE_ITEMS';
export const ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE = 'ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_FAILED = 'GET_BRANCH_FAIL';
export const BRANCH_SEARCH_TEXT = 'BRANCH_SEARCH_TEXT';
export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';
export const SET_INWARD_OUTWARD_SUBTYPE = 'SET_INWARD_OUTWARD_SUBTYPE';
export const SET_SELECTED_DESTINATION_BRANCH = 'SET_SELECTED_DESTINATION_BRANCH';
export const HANDLE_TRANSPORT_DONE = 'HANDLE_TRANSPORT_DONE';
export const HANDLE_VOUCHER_DETAILS_DONE = 'HANDLE_VOUCHER_DETAILS_DONE';
export const RESET_SHIPPING_DETAILS = 'RESET_SHIPPING_DETAILS';
export const ADD_LINE_ITEM_CRDR = 'ADD_LINE_ITEM_CRDR';
export const FETCH_VOUCHER_BY_NUMBER_SUCCESS = 'FETCH_VOUCHER_BY_NUMBER_SUCCESS';
export const FETCH_VOUCHER_BY_NUMBER_FAILURE = 'FETCH_VOUCHER_BY_NUMBER_FAILURE';
export const FETCH_PARTY_STATEMENT_SHARE_REQUEST = 'FETCH_PARTY_STATEMENT_SHARE_REQUEST';
export const FETCH_PARTY_STATEMENT_SHARE_SUCCESS = 'FETCH_PARTY_STATEMENT_SHARE_SUCCESS';
export const FETCH_PARTY_STATEMENT_SHARE_FAILURE = 'FETCH_PARTY_STATEMENT_SHARE_FAILURE';

export const FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST =
  'FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST';
export const FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS =
  'FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS';
export const FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE =
  'FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE';

export const FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST =
  'FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST';
export const FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS =
  'FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS';
export const FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE =
  'FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE';

export const FETCH_CATALOGUE_ITEMS_REQUEST = 'FETCH_CATALOGUE_ITEMS_REQUEST';
export const FETCH_CATALOGUE_ITEMS_SUCCESS = 'FETCH_CATALOGUE_ITEMS_SUCCESS';
export const FETCH_CATALOGUE_ITEMS_FAILURE = 'FETCH_CATALOGUE_ITEMS_FAILURE';

export const ADD_CATALOGUE_ITEMS_REQUEST = 'ADD_CATALOGUE_ITEMS_REQUEST';
export const ADD_CATALOGUE_ITEMS_SUCCESS = 'ADD_CATALOGUE_ITEMS_SUCCESS';
export const ADD_CATALOGUE_ITEMS_FAILURE = 'ADD_CATALOGUE_ITEMS_FAILURE';

export const RENAME_CATALOGUE_ITEMS_REQUEST = 'RENAME_CATALOGUE_ITEMS_REQUEST';
export const RENAME_CATALOGUE_ITEMS_SUCCESS = 'RENAME_CATALOGUE_ITEMS_SUCCESS';
export const RENAME_CATALOGUE_ITEMS_FAILURE = 'RENAME_CATALOGUE_ITEMS_FAILURE';

export const REMOVE_CATALOGUE_ITEMS_REQUEST = 'REMOVE_CATALOGUE_ITEMS_REQUEST';
export const REMOVE_CATALOGUE_ITEMS_SUCCESS = 'REMOVE_CATALOGUE_ITEMS_SUCCESS';
export const REMOVE_CATALOGUE_ITEMS_FAILURE = 'REMOVE_CATALOGUE_ITEMS_FAILURE';

export const DELETE_CATALOGUE_REQUEST = 'DELETE_CATALOGUE_REQUEST';
export const DELETE_CATALOGUE_SUCCESS = 'DELETE_CATALOGUE_SUCCESS';
export const DELETE_CATALOGUE_FAILURE = 'DELETE_CATALOGUE_FAILURE';

export const TOGGLE_CATALOGUE_ACCESS_REQUEST = 'TOGGLE_CATALOGUE_ACCESS_REQUEST';
export const TOGGLE_CATALOGUE_ACCESS_SUCCESS = 'TOGGLE_CATALOGUE_ACCESS_SUCCESS';
export const TOGGLE_CATALOGUE_ACCESS_FAILURE = 'TOGGLE_CATALOGUE_ACCESS_FAILURE';

export const UPDATE_CATALOGUE_SETTINGS_REQUEST = 'UPDATE_CATALOGUE_SETTINGS_REQUEST';
export const UPDATE_CATALOGUE_SETTINGS_SUCCESS = 'UPDATE_CATALOGUE_SETTINGS_SUCCESS';
export const UPDATE_CATALOGUE_SETTINGS_FAILURE = 'UPDATE_CATALOGUE_SETTINGS_FAILURE';

export const FETCH_LAST_USED_CATALOGUE_SETTINGS_REQUEST =
  'FETCH_LAST_USED_CATALOGUE_SETTINGS_REQUEST';
export const FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS =
  'FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS';
export const FETCH_LAST_USED_CATALOGUE_SETTINGS_FAILURE =
  'FETCH_LAST_USED_CATALOGUE_SETTINGS_FAILURE';

export const CATALOGUE_CREATE_REQUEST = 'CATALOGUE_CREATE_REQUEST';
export const CATALOGUE_CREATE_SUCCESS = 'CATALOGUE_CREATE_SUCCESS';
export const CATALOGUE_CREATE_FAILURE = 'CATALOGUE_CREATE_FAILURE';

export const RESET_CATALOGUE_LIST = 'RESET_CATALOGUE_LIST';

export const TOGGLE_EDIT_CATALOGUE_ITEMS_MODE = 'TOGGLE_EDIT_CATALOGUE_ITEMS_MODE';

export const FETCH_PARTY_DESIGN_CODES_SUCCESS = 'FETCH_PARTY_DESIGN_CODES_SUCCESS';
export const FETCH_PARTY_DESIGN_CODES_FAILED = 'FETCH_PARTY_DESIGN_CODES_FAILED';
export const FETCH_PARTY_DESIGN_CODES_REQUESTED = 'FETCH_PARTY_DESIGN_CODES_REQUESTED';

export const FETCH_INPROGRESS_VOUCHERS_SUCCESS = 'FETCH_INPROGRESS_VOUCHERS_SUCCESS';
export const FETCH_INPROGRESS_VOUCHERS_FAILED = 'FETCH_INPROGRESS_VOUCHERS_FAILED';
export const FETCH_INPROGRESS_VOUCHERS_INITIATED = 'FETCH_INPROGRESS_VOUCHERS_INITIATED';

export const FETCH_UNRECONCILED_VOUCHERS_SUCCESS = 'FETCH_UNRECONCILED_VOUCHERS_SUCCESS';
export const FETCH_UNRECONCILED_VOUCHERS_FAILED = 'FETCH_UNRECONCILED_VOUCHERS_FAILED';
export const FETCH_UNRECONCILED_VOUCHERS_INITIATED = 'FETCH_UNRECONCILED_VOUCHERS_INITIATED';

export const FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS =
  'FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS';
export const FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED =
  'FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED';
export const FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED =
  'FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED';

export const SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER =
  'SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER';
export const RESET_VOUCHER_ITEMS = 'RESET_VOUCHER_ITEMS';

export const HANDLE_TOGGLE_VOUCHER_VIEW_MODE = 'HANDLE_TOGGLE_VOUCHER_VIEW_MODE';
export const SET_DUPLICATE_VOUCHER_TYPE = 'SET_DUPLICATE_VOUCHER_TYPE';
export const CHANGE_CATALOGUE_SETTINGS = 'CHANGE_CATALOGUE_SETTINGS';
export const SET_CATALOGUE_SETTINGS = 'SET_CATALOGUE_SETTINGS';

export const ACCOUNT_FORM_SUCCESS = 'ACCOUNT_FORM_SUCCESS';
export const ACCOUNT_FORM_REQUESTED = 'ACCOUNT_FORM_REQUESTED';
export const ACCOUNT_FORM_FAILED = 'ACCOUNT_FORM_FAILED';

export const GET_CURRENT_BALANCE_SUCCESS = 'GET_CURRENT_BALANCE_SUCCESS';
export const GET_CURRENT_BALANCE_REQUESTED = 'GET_CURRENT_BALANCE_REQUESTED';
export const GET_CURRENT_BALANCE_FAILED = 'GET_CURRENT_BALANCE_FAILED';

//purchaseVoucherList specific
export const FETCH_VOUCHER_SUCCESSED = ' FETCH_VOUCHER_SUCCESSED,';
export const FETCH_VOUCHER_FAILED = 'FETCH_VOUCHER_FAILED,';

export const UPDATE_PARTY_NAME = 'UPDATE_PARTY_NAME';
export const FETCHED_VOUCHER_TYPE = 'FETCHED_VOUCHER_TYPE';

export const RECONCILE_VOUCHERS_SUCCESS = 'RECONCILE_VOUCHERS_SUCCESS';
export const RECONCILE_VOUCHERS_FAILED = 'RECONCILE_VOUCHERS_FAILED';
export const RECONCILE_VOUCHERS_ERROR_RESET = 'RECONCILE_VOUCHERS_ERROR_RESET';
// reports
export const FETCH_TRIAL_BALANCE_SUCCESS = 'FETCH_TRIAL_BALANCE_SUCCESS';
export const FETCH_TRIAL_BALANCE_FAIL = 'FETCH_TRIAL_BALANCE_FAIL';
export const SHOW_DETAIL_VIEW = 'SHOW_DETAIL_VIEW';
export const SET_ROUTES_DETAILS = 'SET_ROUTES_DETAILS';
export const SET_ROUTING_STATUS = 'SET_ROUTING_STATUS';
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
export const GET_DATE_RANGE = 'GET_DATE_RANGE';
export const SHOW_LEDGER_VIEW = 'SHOW_LEDGER_VIEW';
export const SET_LEDGER_DETAILS = 'SET_LEDGER_DETAILS';
export const RESET_TO_REPORT_MODE_HOME = 'RESET_TO_REPORT_MODE_HOME';
export const RESET_GROUP_DETAILS = 'RESET_GROUP_DETAILS';
export const SHOW_YEARLY_VIEW = 'SHOW_YEARLY_VIEW';
export const SET_YEARLY_DETAILS = 'SET_YEARLY_DETAILS';
export const SHOW_MONTHLY_VIEW = 'SHOW_MONTHLY_VIEW';
export const SHOW_DAY_VIEW = 'SHOW_DAY_VIEW';
export const SHOW_VOUCHER_DUE_LIST = 'SHOW_VOUCHER_DUE_LIST';
export const SHOW_PARTY_VOUCHER_DUE_LIST = 'SHOW_PARTY_VOUCHER_DUE_LIST';
export const SHOW_INVENTORY_VIEW = 'SHOW_INVENTORY_VIEW';
export const SHOW_ACCOUNTS_VIEW = 'SHOW_ACCOUNTS_VIEW';
export const SHOW_ACCOUNT_VIEW = 'SHOW_ACCOUNT_VIEW';
export const SHOW_INVENTORY_GROUP_VIEW = 'SHOW_INVENTORY_GROUP_VIEW';
export const SHOW_INVENTORY_ALL_ITEMS_VIEW = 'SHOW_INVENTORY_ALL_ITEMS_VIEW';
export const SHOW_GSTR1_VIEW = 'SHOW_GSTR1_VIEW';
export const SHOW_GSTR1_GROUP_VIEW = 'SHOW_GSTR1_GROUP_VIEW';
export const SHOW_GSTR1_STATEMENT_VIEW = 'SHOW_GSTR1_STATEMENT_VIEW';
export const FETCH_INVENTORY_MONTH_VIEW = 'FETCH_INVENTORY_MONTH_VIEW';
export const SHOW_PERIOD_DIALOG = 'SHOW_PERIOD_DIALOG';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
export const SET_ITEM_ERROR = 'SET_ITEM_ERROR';
export const EXPAND_ACCOUNT = 'EXPAND_ACCOUNT';
export const EXPAND_ALL_ACCOUNT = 'EXPAND_ALL_ACCOUNT';
export const SET_PROFIT_LOSS = 'SET_PROFIT_LOSS';
export const SET_REPORT_MODE = 'SET_REPORT_MODE';
export const SET_BALANCE_SHEET = 'SET_BALANCE_SHEET';
export const SET_DAY_BOOK_LIST = 'SET_DAY_BOOK_LIST';
export const SET_REPORT_VOUCHER_TYPE = 'SET_REPORT_VOUCHER_TYPE';
export const SET_DAY_BOOK_ACCOUNT_TYPE = 'SET_DAY_BOOK_ACCOUNT_TYPE';
export const SET_INVENTORY_LIST = 'SET_INVENTORY_LIST';
export const SET_INVENTORY_GROUP_LIST = 'SET_INVENTORY_GROUP_LIST';
export const REPORTS_DATA_REQUESTED = 'REPORTS_DATA_REQUESTED';
export const REPORTS_DATA_SUCCESS = 'REPORTS_DATA_SUCCESS';
export const REPORTS_DATA_FAILED = 'REPORTS_DATA_FAILED';

export const EXPORT_REPORTS_DATA_REQUESTED = 'EXPORT_REPORTS_DATA_REQUESTED';
export const EXPORT_REPORTS_DATA_SUCCESS = 'EXPORT_REPORTS_DATA_SUCCESS';
export const EXPORT_REPORTS_DATA_FAILED = 'EXPORT_REPORTS_DATA_FAILED';

export const TOGGLE_REPORT_FILTERS = 'TOGGLE_REPORT_FILTERS';
export const RESET_REPORT_FILTERS = 'RESET_REPORT_FILTERS';
export const SET_REPORT_PARTY_FILTER = 'SET_REPORT_PARTY_FILTER';
export const SET_REPORT_TAGS_FILTER = 'SET_REPORT_TAGS_FILTER';
export const SET_REPORT_ITEM_GROUP_FILTER = 'SET_REPORT_ITEM_GROUP_FILTER';
export const SET_REPORT_ITEM_TAGS_FILTER = 'SET_REPORT_ITEM_TAGS_FILTER';
export const SET_REPORT_BRANCH_FILTER = 'SET_REPORT_BRANCH_FILTER';
export const SET_REPORT_SEARCH_TEXT = 'SET_REPORT_SEARCH_TEXT';
export const TOGGLE_REPORT_BARCODE_SEARCH = 'TOGGLE_REPORT_BARCODE_SEARCH';
export const SET_REPORT_CITY_FILTER = 'SET_REPORT_CITY_FILTER';
export const SET_REPORT_INTERVAL_FILTER = 'SET_REPORT_INTERVAL_FILTER';
export const RESET_REPORT_CITY_FILTER = 'RESET_REPORT_CITY_FILTER';
export const SET_NUMERIC_BARCODE_FILTER = 'SET_NUMERIC_BARCODE_FILTER';
export const SET_REPORT_PARTIES_STATES_FILTER = 'SET_REPORT_PARTIES_STATES_FILTER';
export const SET_REPORT_VOUCHER_TYPE_FILTER = 'SET_REPORT_VOUCHER_TYPE_FILTER';
export const SET_REPORT_DATE_FILTER = 'SET_REPORT_DATE_FILTER';
export const SET_REPORT_DATE_FILTER_RANGE = 'SET_REPORT_DATE_FILTER_RANGE';
export const SET_REPORT_DUE_DATE_FILTER_RANGE = 'SET_REPORT_DUE_DATE_FILTER_RANGE';
export const SET_REPORT_INCLUDE_TYPE_FILTER = 'SET_REPORT_INCLUDE_TYPE_FILTER';
export const SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER = 'SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER';

export const FETCH_STATE_REPORT_SUCCESS = 'FETCH_STATE_REPORT_SUCCESS';
export const FETCH_STATE_REPORT_FAIL = 'FETCH_STATE_REPORT_FAIL';

export const FETCH_CITY_REPORT_SUCCESS = 'FETCH_CITY_REPORT_SUCCESS';
export const FETCH_CITY_REPORT_FAIL = 'FETCH_CITY_REPORT_FAIL';
// General
export const FETCH_IFSC_CODE_SUCCESS = 'FETCH_IFSC_CODE_SUCCESS';
export const FETCH_IFSC_CODE_FAILURE = 'FETCH_IFSC_CODE_FAILURE';
export const ACTION_SAVE_BUTTON_DISABLE = 'ACTION_SAVE_BUTTON_DISABLE';

export const FETCH_TAXES_SUCCESS = 'FETCH_TAXES_SUCCESS';
export const FETCH_TAXES_FAILURE = 'FETCH_TAXES_FAILURE';

export const FETCH_GSTN_DETAILS_REQUESTED = 'FETCH_GSTN_DETAILS_REQUESTED';
export const FETCH_GSTN_DETAILS_SUCCESS = 'FETCH_GSTN_DETAILS_SUCCESS';
export const FETCH_GSTN_DETAILS_FAILED = 'FETCH_GSTN_DETAILS_FAILED';

export const FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED = 'FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED';
export const FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS = 'FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS';
export const FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED = 'FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED';

export const FETCH_VOUCHER_DUE_AMOUNT_REQUESTED = 'FETCH_VOUCHER_DUE_AMOUNT_REQUESTED';
export const FETCH_VOUCHER_DUE_AMOUNT_SUCCESS = 'FETCH_VOUCHER_DUE_AMOUNT_SUCCESS';
export const FETCH_VOUCHER_DUE_AMOUNT_FAILED = 'FETCH_VOUCHER_DUE_AMOUNT_FAILED';

export const FETCH_VOUCHER_TYPES_SUCCESS = 'FETCH_VOUCHER_TYPES_SUCCESS';
export const FETCH_VOUCHER_TYPES_FAILED = 'FETCH_VOUCHER_TYPES_FAILED';

//Modals
export const HANDLE_TOGGLE_MODAL = 'HANDLE_TOGGLE_MODAL';

//Contact
export const FETCH_CONTACT_FAILED = 'FETCH_CONTACT_FAILED';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';

//Snackbar
export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

//Image Zoom Box
export const IMAGE_ZOOM_BOX_OPEN = 'IMAGE_ZOOM_BOX_OPEN';
export const IMAGE_ZOOM_BOX_CLOSE = 'IMAGE_ZOOM_BOX_CLOSE';

// Progress Tracker

export const FETCH_PROGRESS_TRACKER_LIST = 'FETCH_PROGRESS_TRACKER_LIST';
export const FETCH_PROGRESS_TRACKER_LIST_SUCCESS = 'FETCH_PROGRESS_TRACKER_LIST_SUCCESS';
export const FETCH_PROGRESS_TRACKER_LIST_FAILED = 'FETCH_PROGRESS_TRACKER_LIST_FAILED';
export const SET_PROGRESS_STATUS_BY_ID = 'SET_PROGRESS_STATUS_BY_ID';

//TableAction

export const TABLE_SELECT = 'TABLE_SELECT';
export const SELECT_RESET = 'SELECT_RESET';
export const SELECT_RESET_COLUMN_VISIBILITY = 'SELECT_RESET_COLUMN_VISIBILITY';
export const RESET_ALL_COLUMN_VISIBILITY = 'RESET_ALL_COLUMN_VISIBILITY';
export const SELECT_HIDDEN_COLUMN = 'SELECT_HIDDEN_COLUMN';
export const SELECT_COLUMN_WIDTH = 'SELECT_COLUMN_WIDTH';
export const RESET_COLUMN_WIDTH = 'RESET_COLUMN_WIDTH';
export const SET_SORTING_COLUMN = 'SET_SORTING_COLUMN';
export const RESET_SORTING_COLUMN = 'RESET_SORTING_COLUMN';
export const SET_THUMBNAIL_VIEW_SELECTION = 'SET_THUMBNAIL_VIEW_SELECTION';
export const SET_EXPANDED_ROW_IDS = 'SET_EXPANDED_ROW_IDS';
export const TOGGLE_ROW_SELECT = 'TOGGLE_ROW_SELECT';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const RESET_TABLE_SELECTION = 'RESET_TABLE_SELECTION';

export const FETCH_STATES_FAILED = 'FETCH_STATES_FAILED';
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';
export const FETCH_STATES_REQUESTED = 'FETCH_STATES_REQUESTED';

export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_REQUESTED = 'FETCH_COUNTRIES_REQUESTED';

export const UPDATE_EXCLUSIVE_DISCOUNT_DATA = 'UPDATE_EXCLUSIVE_DISCOUNT_DATA';

export const FETCH_INDUSTRY_TYPES_FAILED = 'FETCH_INDUSTRY_TYPES_FAILED';
export const FETCH_INDUSTRY_TYPES_SUCCESS = 'FETCH_INDUSTRY_TYPES_SUCCESS';
export const FETCH_INDUSTRY_TYPES_REQUESTED = 'FETCH_INDUSTRY_TYPES_REQUESTED';

export const FETCH_PARTY_ACCOUNT_TYPES_FAILED = 'FETCH_PARTY_ACCOUNT_TYPES_FAILED';
export const FETCH_PARTY_ACCOUNT_TYPES_SUCCESS = 'FETCH_PARTY_ACCOUNT_TYPES_SUCCESS';
export const FETCH_PARTY_ACCOUNT_TYPES_REQUESTED = 'FETCH_PARTY_ACCOUNT_TYPES_REQUESTED';

export const FETCH_CITIES_FAILED = 'FETCH_CITIES_FAILED';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_REQUESTED = 'FETCH_CITIES_REQUESTED';

export const FETCH_PARTIES_STATES_FAILED = 'FETCH_PARTIES_STATES_FAILED';
export const FETCH_PARTIES_STATES_SUCCESS = 'FETCH_PARTIES_STATES_SUCCESS';
export const FETCH_PARTIES_STATES_REQUESTED = 'FETCH_PARTIES_STATES_REQUESTED';

//Voucher Calc realted
export const GET_CALCULATED_ITEMS = 'GET_CALCULATED_ITEMS';
export const GET_CALCULATED_ITEM = 'GET_CALCULATED_ITEM';
export const GET_AUTO_POPULATE_ROUNDOFF_DATA = 'GET_AUTO_POPULATE_ROUNDOFF_DATA';
export const GET_LINE_AMOUNT_SUM = 'GET_LINE_AMOUNT_SUM';
export const GET_BILL_TAX_AMOUNT = 'GET_BILL_TAX_AMOUNT';
export const GET_BILL_ITEMS_PRICE = 'GET_BILL_ITEMS_PRICE';
export const GET_BILL_DISCOUNT_AMOUNT = 'GET_BILL_DISCOUNT_AMOUNT';
export const GET_BILL_AMT_AND_DUE_AMT = 'GET_BILL_AMT_AND_DUE_AMT';
export const GET_OTHER_CHARGES_TOTAL = 'GET_OTHER_CHARGES_TOTAL';
export const GET_OTHER_CHARGES_AFTER_TAX_TOTAL = 'GET_OTHER_CHARGES_AFTER_TAX_TOTAL';
export const GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT = 'GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT';
export const GET_COUPON_DISCOUNT_AMOUNT = 'GET_COUPON_DISCOUNT_AMOUNT';
export const GET_TAX = 'GET_TAX';
export const GET_TAX_ANALYSIS = 'GET_TAX_ANALYSIS';
export const GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL = 'GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL';
export const SET_CALCULATION_STATUS = 'SET_CALCULATION_STATUS';
export const SET_CALCULATION_STATUS_ALL = 'SET_CALCULATION_STATUS_ALL';
export const VOUCHER_CALC_TOGGLE = 'VOUCHER_CALC_TOGGLE';

//marketing related

export const FETCH_CONVERSATION_CHANNEL_LIST = 'FETCH_CONVERSATION_CHANNEL_LIST';
export const FETCH_CHANNEL_CONVERSATION = 'FETCH_CHANNEL_CONVERSATION';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';
export const MARKETING_API_REQUEST = 'MARKETING_API_REQUEST';
export const MARKETING_API_SUCCESS = 'MARKETING_API_SUCCESS';
export const POST_CHANNEL_CONVERSATION_REQUEST = 'POST_CHANNEL_CONVERSATION_REQUEST';
export const POST_CHANNEL_CONVERSATION_SUCCESS = 'POST_CHANNEL_CONVERSATION_SUCCESS';
export const POST_CHANNEL_CONVERSATION_ERROR = 'POST_CHANNEL_CONVERSATION_ERROR';

export const SELECT_PREPAREDBY_FILTER = 'SELECT_PREPAREDBY_FILTER';
export const SELECT_PARTY_FILTER = 'SELECT_PARTY_FILTER';
export const SELECT_ACCOUNT_FILTER = 'SELECT_ACCOUNT_FILTER';
export const SELECT_STATUS_FILTER = 'SELECT_STATUS_FILTER';
export const SELECT_DUE_AMOUNT_FILTER = 'SELECT_DUE_AMOUNT_FILTER';
export const SELECT_BRANCH_FILTER = 'SELECT_BRANCH_FILTER';
export const SELECT_READ_LOCK_FILTER = 'SELECT_READ_LOCK_FILTER';
export const SELECT_UPDATE_LOCK_FILTER = 'SELECT_UPDATE_LOCK_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const HIDDEN_VOUCHER_LIST_SUCCESS = 'HIDDEN_VOUCHER_LIST_SUCCESS';
export const HIDDEN_VOUCHER_LIST_ERROR = 'HIDDEN_VOUCHER_LIST_ERROR';
export const HIDDEN_VOUCHER_LIST_REQUESTED = 'HIDDEN_VOUCHER_LIST_REQUESTED';
export const CHANGE_FINANCIAL_YEAR = 'CHANGE_FINANCIAL_YEAR';
export const SAVE_PARENT_VOUCHER_OTHERCHARGES = 'SAVE_PARENT_VOUCHER_OTHERCHARGES';
export const SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF = 'SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF';
export const RESET_PARENT_CHILD_VOUCHER_DATA = 'RESET_PARENT_CHILD_VOUCHER_DATA';
export const TOGGLE_CHILD_ERROR_FLAG = 'TOGGLE_CHILD_ERROR_FLAG';
export const SET_INTERVAL = 'SET_INTERVAL';
export const RESET_PARTY_FILTERS = 'RESET_PARTY_FILTERS';
export const SET_VOUCHER_ITEM_LIST = 'SET_VOUCHER_ITEM_LIST';
export const SET_SUBTYPE_FILTER = 'SET_SUBTYPE_FILTER';

export const SET_PARTY_ACCOUNT_LIST_FILTER = 'SET_PARTY_ACCOUNT_LIST_FILTER';
export const RESET_PARTY_ACCOUNT_LIST_FILTER = 'RESET_PARTY_ACCOUNT_LIST_FILTER';

export const PROGRESS_TRACKER = 'PROGRESS_TRACKER';

export const TOGGLE_RECORD_RECEIPT_MODAL = 'TOGGLE_RECORD_RECEIPT_MODAL';
export const TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL = 'TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL';
export const TOGGLE_RECORD_RP_FROM_VOUCHER = 'TOGGLE_RECORD_RP_FROM_VOUCHER';
export const SET_RECORD_RP_DATA_FROM_VOUCHER = 'SET_RECORD_RP_DATA_FROM_VOUCHER';

//Guides
export const SET_GUIDE_STATUS = 'SET_GUIDE_STATUS';
export const SET_GUIDE_STEP_INDEX = 'SET_GUIDE_STEP_INDEX';
export const SET_GUIDE_STEP_INDEX_NEXT = 'SET_GUIDE_STEP_INDEX_NEXT';
export const SET_GUIDE_STEP_INDEX_PREV = 'SET_GUIDE_STEP_INDEX_PREV';
export const SET_GUIDE_PROMPTS_STATUS = 'SET_GUIDE_PROMPTS_STATUS';
export const RESET_GUIDE_PROMPTS = 'RESET_GUIDE_PROMPTS';
export const TOGGLE_REPORT_PARTY_CODE = 'TOGGLE_REPORT_PARTY_CODE';

export const RESET_VOUCHER_DIALOG_FILTERS = 'RESET_VOUCHER_DIALOG_FILTERS';
export const TOGGLE_REPORT_ITEM_LABEL = 'TOGGLE_REPORT_ITEM_LABEL';
export const TOGGLE_REPORT_EXPORT_BUTTONS = 'TOGGLE_REPORT_EXPORT_BUTTONS';

export const RESET_ITEM_AVAILABLE_QTY_LIST = 'RESET_ITEM_AVAILABLE_QTY_LIST';

// preserve reducer type
export const PRESERVE_VOUCHER_DATES = 'PRESERVE_VOUCHER_DATES';
export const PRESERVE_VOUCHER_PICKUP_LOCATION = 'PRESERVE_VOUCHER_PICKUP_LOCATION';
export const PRESERVE_VOUCHER_TRANSPORT = 'PRESERVE_VOUCHER_TRANSPORT';

export const UPDATE_USER_PROFILE_PICTURE_REQUESTED = 'UPDATE_USER_PROFILE_PICTURE_REQUESTED';
export const UPDATE_USER_PROFILE_PICTURE_SUCCESS = 'UPDATE_USER_PROFILE_PICTURE_SUCCESS';
export const UPDATE_USER_PROFILE_PICTURE_FAILED = 'UPDATE_USER_PROFILE_PICTURE_FAILED';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';

// business card
export const FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED =
  'FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED';
export const FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS =
  'FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS';
export const FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED =
  'FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED';
export const FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED =
  'FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED';
export const FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS =
  'FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS';
export const FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED =
  'FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED';
export const SET_VOUCHER_CREATION_MODE = 'SET_VOUCHER_CREATION_MODE';
export const POS_BILLING_PARTY = 'POS_BILLING_PARTY';

export const SET_NARRATION_FILTER = 'SET_NARRATION_FILTER';
// daybook
export const FETCH_REPORT_VOUCHERS_LIST = 'FETCH_REPORT_VOUCHERS_LIST';
export const SET_REPORT_VOUCHER_FILTER = 'SET_REPORT_VOUCHER_FILTER';

export const TOOGLE_PICKUP_LOCATION_LINK = 'TOOGLE_PICKUP_LOCATION_LINK';
export const UPDATE_PICKUP_LOCATION_DETAILS = 'UPDATE_PICKUP_LOCATION_DETAILS';
// parties
export const SET_STATEMENT_INCLUDE_TYPE_FILTER = 'SET_STATEMENT_INCLUDE_TYPE_FILTER';
export const SET_ISHOP_STATUS_FILTER = 'SET_ISHOP_STATUS_FILTER';
export const SET_OUT_OF_STOCK_FILTER = 'SET_OUT_OF_STOCK_FILTER';
export const SET_REPORT_SALES_PERSON_FILTER = 'SET_REPORT_SALES_PERSON_FILTER';
export const SET_SALES_PERSON_DETAILS = 'SET_SALES_PERSON_DETAILS';

export const RESET_ITEM_GROUP_PAGE_NO = 'RESET_ITEM_GROUP_PAGE_NO';
export const RESET_SELECTED_VOUCHER = 'RESET_SELECTED_VOUCHER';
export const ITEM_SEARCH_TEXT = 'ITEM_SEARCH_TEXT';
export const RESET_SELECTED_ROW_IDS = 'RESET_SELECTED_ROW_IDS';
export const SET_ITEM_FILTER_TOGGLE_LIST = 'SET_ITEM_FILTER_TOGGLE_LIST';
export const SET_ITEM_BULK_TOGGLE = 'SET_ITEM_BULK_TOGGLE';
export const SET_ITEM_BULK_SETTING = 'SET_ITEM_BULK_SETTING';
export const SET_BULK_ITEMS = 'SET_BULK_ITEMS';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const RESET_BULK_ITEM = 'RESET_BULK_ITEM';
export const UPDATE_ITEM_FILTER_TOGGLE_LIST = 'UPDATE_ITEM_FILTER_TOGGLE_LIST';
export const SET_SUB_TYPE = 'SET_SUB_TYPE';
export const HANDLE_PARTY_CREATION_MODE = 'HANDLE_PARTY_CREATION_MODE';
export const SET_EDIT_VOUCHER_ID = 'SET_EDIT_VOUCHER_ID';
export const UPDATE_LINKED_ESTIMATION_STATUS = 'UPDATE_LINKED_ESTIMATION_STATUS';
export const SET_VENDOR_WISE_DETAILS = 'SET_VENDOR_WISE_DETAILS';

export const SET_INTEREST_CALCULATION_DATE = 'SET_INTEREST_CALCULATION_DATE';
export const SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE =
  'SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE';
export const SET_INTEREST_CALCULATION_BRANCH = 'SET_INTEREST_CALCULATION_BRANCH';
export const RESET_INTEREST_CALCULATION_FILTERS = 'RESET_INTEREST_CALCULATION_FILTERS';

export const FETCH_INTEREST_CALCULATION_REQUESTED = 'FETCH_INTEREST_CALCULATION_REQUESTED';
export const FETCH_INTEREST_CALCULATION_SUCCESS = 'FETCH_INTEREST_CALCULATION_SUCCESS';
export const FETCH_INTEREST_CALCULATION_FAILED = 'FETCH_INTEREST_CALCULATION_FAILED';

export const SET_PRINTING_DIALOG_STATUS = 'SET_PRINTING_DIALOG_STATUS';
export const SET_VOUCHER_FOR_PRINT = 'SET_VOUCHER_FOR_PRINT';
